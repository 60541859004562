import React, { useEffect, useState } from "react";
import cx from "classnames";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";

import { useForm } from "./FormContext";

import ProductCodeInput from "./ProductCodeInput";
import DatePicker from "modules/common/components/DatePicker";
import ShareDataWithPartner from "./ShareDataWithPartner";
import Button from "modules/common/components/Button";
import Error from "modules/common/components/Error";

import style from "./ProductRegistration.module.css";
import { SpecificProductType } from "../data/types";

const messages = defineMessages({
  date: {
    id: "product-registration.date-picker.label",
    defaultMessage: "Date of commissioning",
    description: "label: date picker to select installation date.",
  },
  robotDate: {
    id: "product-registration.date-picker.robotLabel",
    defaultMessage: "Date of purchasing",
    description:
      "label: date picker to select purchasing date pool robot specific.",
  },
  button: {
    id: "button.next",
    defaultMessage: "Next",
    description: "button text: go to the next stage of the form.",
  },
});

export default function ProductRegistration() {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();
  const { push } = useHistory();

  const lastProductResponseData = state?.lastProductResponse?.data;

  const isRobot =
    lastProductResponseData?.specificProductType ===
    SpecificProductType.PoolRobot;

  useEffect(() => {
    localStorage.removeItem("cached-form-data");
  }, []);

  const [lastErrorCode, setLastErrorCode] = useState(null);
  const showError = !!lastErrorCode;

  return (
    <div>
      <Error show={showError} errorCode={lastErrorCode} />
      <div className={style.root}>
        <div
          style={{
            backgroundImage: `url(${lastProductResponseData?.imageUrl})`,
          }}
          className={cx(style.img, lastProductResponseData && style.show)}
        />
        <div className={style.content}>
          <ProductCodeInput setLastErrorCode={setLastErrorCode} />
          <div className={style.spacer} />
          <DatePicker
            label={formatMessage(isRobot ? messages.robotDate : messages.date)}
            defaultValue={state.form.installationDate}
            updateParent={(date: Date) => {
              setInState("form", "installationDate", date);
            }}
            blockFutureDates={true}
          />
          <ShareDataWithPartner />
          <div className={style.btn}>
            <Button
              name={formatMessage(messages.button)}
              onClick={() => push("/redirect")}
              btnStyle="primary"
              disabled={!lastProductResponseData}
            />
            </div>
        </div>
      </div>
    </div>
  );
}
