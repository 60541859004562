import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";

import { useForm } from "../FormContext";

import CheckBox from "modules/common/components/CheckBox";

import style from "./AgreeStatusUpdates.module.css";

const messages = defineMessages({
  label: {
    id: "agree-status-updates.label",
    defaultMessage:
      "Yes, I would like to be informed about status changes of my device by BWT.",
    description:
      "label: select whether user is willing to receive device status updates.",
  },
});

export default function AgreeStatusUpdates() {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();
  useEffect(() => {
    setInState("iot", "agreeStatusUpdates", true);
  }, [setInState]);
  return (
    <div className={style.root}>
      <CheckBox
        label={formatMessage(messages.label)}
        checked={state.iot?.agreeStatusUpdates ?? true}
        onClick={(agreeStatusUpdates: boolean) =>
          setInState("iot", "agreeStatusUpdates", agreeStatusUpdates)
        }
      />
    </div>
  );
}
