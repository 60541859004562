import React, { useEffect, useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { useForm, DrinkPro20State } from "../FormContext";
import { getDrinkPro20Data } from "modules/api/endpoints";
import {
  FilterCartridge,
  Co2Bottle as Co2BottleEnum,
  DrinkPro20Data,
} from "../../data/types";
import drinkPro20DefaultData from "../../data/drinkPro20";

import RoomSize from "./RoomSize";
import PeopleInTheHouse from "../PeopleInTheHouse";
import CategoryTitle from "modules/common/components/CategoryTitle";
import NumericInput from "modules/common/components/NumericInput";
import DropDown from "modules/common/components/DropDown";
import CheckBox from "modules/common/components/CheckBox";

import style from "./DrinkPro20Form.module.css";

const messages = defineMessages({
  consumption: {
    id: "drink-pro-20.form.title.water-consumption",
    defaultMessage: "Water consumption",
    description: "sub section of the drink pro 20 form for water consumption.",
  },
  dailyWater: {
    id: "drink-pro-20.form.label.daily-water-usage",
    defaultMessage: "Daily in litres",
    description: "input label: daily water usage.",
  },
  sodaWater: {
    id: "drink-pro-20.form.label.daily-soda-water-usage",
    defaultMessage: "thereof soda water",
    description: "input label: daily soda water usage",
  },
  roomVolume: {
    id: "drink-pro-20.form.label.room-volume",
    defaultMessage: "Room volume",
    description: "input label: room volume",
  },
  currentCartridge: {
    id: "drink-pro-20.form.label.current-cartridge",
    defaultMessage: "Current cartridge",
    description: "input label: current cartridge",
  },
  installedBottle: {
    id: "drink-pro-20.form.label.installed-co2-bottle",
    defaultMessage: "Installed CO2 bottle",
    description: "input label: installed co2 bottle type.",
  },
  hasIC50: {
    id: "drink-pro-20.form.label.has-ic50",
    defaultMessage: "Simply Care IC50 (optional)",
    description: "input label: cartridge has ic50.",
  },
});

function getShowWaterUsageWarning(waterUsage?: number, sodaUsage?: number) {
  if (waterUsage === undefined || sodaUsage === undefined) return false;
  if (sodaUsage > waterUsage) return true;
}

function getShowCO2Warning(minRoomSize?: number, drinkPro20?: DrinkPro20State) {
  if (minRoomSize === undefined) return false;
  if (drinkPro20?.volume !== undefined) {
    if (minRoomSize > drinkPro20.volume) return true;
  }
  if (drinkPro20?.height !== undefined && drinkPro20?.width !== undefined) {
    const volume = drinkPro20.height * drinkPro20.width;
    if (minRoomSize > volume) return true;
  }
  return false;
}

function DrinkPro20Form() {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();

  const [data, setData] = useState<DrinkPro20Data>(drinkPro20DefaultData);

  useEffect(() => {
    const runEffect = async () => {
      const { success, data } = await getDrinkPro20Data();
      if (success) {
        if (data) {
          setData(data);
          setInState(
            "drinkPro20",
            "currentCartridge",
            data.cartridges[0].filterCartridge
          );
          setInState(
            "drinkPro20",
            "installedCO2Bottle",
            data.co2Bottles[0].co2Bottle
          );
        }
      } else {
        setInState(
          "drinkPro20",
          "currentCartridge",
          drinkPro20DefaultData.cartridges[0].filterCartridge
        );
        setInState(
          "drinkPro20",
          "installedCO2Bottle",
          drinkPro20DefaultData.co2Bottles[0].co2Bottle
        );
      }
    };
    runEffect();
  }, [setInState]);

  const selectedCartridgeData = data.cartridges.find(
    (c) => c.filterCartridge === state.drinkPro20?.currentCartridge
  );
  const selectedBottleData = data.co2Bottles.find(
    (c) => c.co2Bottle === state.drinkPro20?.installedCO2Bottle
  );

  const canSelectIC50 = selectedCartridgeData?.supportsIC50;
  const showCO2Warning = getShowCO2Warning(
    selectedBottleData?.requiredRoomVolume,
    state.drinkPro20
  );
  const showWaterUsageWarning = getShowWaterUsageWarning(
    state?.drinkPro20?.dailyWaterUsage,
    state?.drinkPro20?.dailySodaUsage
  );

  return (
    <div className={style.root}>
      <div className={style.seg}>
        <CategoryTitle title={formatMessage(messages.consumption)} />
        <div className={style.consumption}>
          <div className={style.water}>
            <NumericInput
              label={formatMessage(messages.dailyWater)}
              update={(dailyWaterUsage) =>
                setInState("drinkPro20", "dailyWaterUsage", dailyWaterUsage)
              }
              defaultValue={state?.drinkPro20?.dailyWaterUsage}
            />
            <NumericInput
              label={formatMessage(messages.sodaWater)}
              update={(dailySodaUsage) =>
                setInState("drinkPro20", "dailySodaUsage", dailySodaUsage)
              }
              defaultValue={state?.drinkPro20?.dailySodaUsage}
              ranges={{ min: 0, max: state?.drinkPro20?.dailyWaterUsage }}
            />
          </div>
          {showWaterUsageWarning && (
            <div className={style.attention}>
              <FormattedMessage
                id="drink-pro-20.form.attention.water-usage"
                defaultMessage="Attention: the daily soda water usage cannot exceed the daily water usage."
                description="validation: impossible water usage - soda can't be higher than total."
              />
            </div>
          )}
        </div>
      </div>
      <div className={style.seg}>
        <CategoryTitle title={formatMessage(messages.roomVolume)} />
        <RoomSize />
      </div>
      <div className={style.seg}>
        <PeopleInTheHouse />
      </div>
      <div className={style.seg}>
        <CategoryTitle title={formatMessage(messages.currentCartridge)} />
        <DropDown
          label={formatMessage(messages.currentCartridge)}
          list={data.cartridges.map((c) => {
            return {
              label: c.displayName,
              value: c.filterCartridge,
            };
          })}
          defaultValue={selectedCartridgeData?.displayName}
          update={(currentCartridge: FilterCartridge) =>
            setInState("drinkPro20", "currentCartridge", currentCartridge)
          }
        />
        {canSelectIC50 && (
          <div style={{ marginTop: 30 }}>
            <CheckBox
              label={formatMessage(messages.hasIC50)}
              checked={state.drinkPro20?.hasIC50 ?? false}
              onClick={(hasIC50) =>
                setInState("drinkPro20", "hasIC50", hasIC50)
              }
            />
          </div>
        )}
      </div>
      <div className={style.seg}>
        <CategoryTitle title={formatMessage(messages.installedBottle)} />
        <DropDown
          label={formatMessage(messages.installedBottle)}
          list={data.co2Bottles.map((c) => {
            return {
              label: c.displayName,
              value: c.co2Bottle,
            };
          })}
          defaultValue={selectedBottleData?.displayName}
          update={(installedCO2Bottle: Co2BottleEnum) =>
            setInState("drinkPro20", "installedCO2Bottle", installedCO2Bottle)
          }
        />
        {showCO2Warning && (
          <div className={style.attention}>
            <FormattedMessage
              id="drink-pro-20.form.attention.co2Warning"
              defaultMessage="Attention: the CO2 bottle you are using is not suitable for you room size. Check your entries or contact the BWT customer service."
              description="validation: co2 warning - bottle capacity too big for room size."
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DrinkPro20Form;
