import React from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import Button from "modules/common/components/Button";

import style from "./ReRegisterOverlay.module.css";
import LoadingSpinner from "modules/common/components/LoadingSpinner";

const messages = defineMessages({
  confirmBtn: {
    id: "device-registration.alert.re-register.button.confirm",
    defaultMessage: "Confirm",
    description: "button: complete device re-registration.",
  },
  cancelBtn: {
    id: "device-registration.alert.re-register.button.cancel",
    defaultMessage: "Cancel",
    description: "button: cancel device re-registration.",
  },
});

interface ReRegisterOverlayProps {
  onConfirm: () => void;
  onReject: () => void;
  sendIsBusy: boolean;
}

export default function ReRegisterOverlay({
  onConfirm,
  onReject,
  sendIsBusy,
}: ReRegisterOverlayProps) {
  const { formatMessage } = useIntl();

  if (sendIsBusy) return <LoadingSpinner />;

  return (
    <div>
      <FormattedMessage
        id="device-registration.alert.re-register.explanation-text"
        defaultMessage="Attention! Your product is already activated. If you proceed all of the previous data will be deleted. Continue?"
        description="alert: confirm to re-register device."
      />
      <div className={style.btnRow}>
        <Button
          name={formatMessage(messages.confirmBtn)}
          btnStyle="primary"
          size="large"
          onClick={onConfirm}
        />
        <Button
          name={formatMessage(messages.cancelBtn)}
          btnStyle="secondary"
          size="large"
          onClick={onReject}
        />
      </div>
    </div>
  );
}
