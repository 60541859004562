import { StoreLink } from "modules/layout/components/ExternalLinks";
import { useExternalLinkItems } from "modules/layout/data/externalLinks";
import React, { RefObject, useRef } from "react";
import { FormattedMessage } from "react-intl";
import Benefits from "../Benefits";

import style from "./NoProductCode.module.css";
import { isGerman } from "modules/util/constants";

import srcAppLoginDE from "assets/App-login-register-de.png";
import srcAppNoCodeDE from "assets/App-no-code-de.png";
import srcAppLoginEN from "assets/App-login-register-en.png";
import srcAppNoCodeEN from "assets/App-no-code-en.png";

interface NoProductCodeProps {
  formRef: RefObject<HTMLDivElement>;
}

const NoProductCode: React.FC<NoProductCodeProps> = ({ formRef }) => {
  const stepOneRef = useRef<HTMLDivElement>(null);
  const stepTwoRef = useRef<HTMLDivElement>(null);
  const stepThreeRef = useRef<HTMLDivElement>(null);

  const ScrollToStep = (ref: React.RefObject<HTMLDivElement>) => {
    if (!ref.current) return;
    const top = ref.current?.offsetTop - 100; // sitecore header is 85px
    window.scrollTo({
      top,
      behavior: "smooth",
    });
  };

  const externalLinkItems = useExternalLinkItems();
  const storeLinks = externalLinkItems.find((item) =>
    item.links.find((link) => link.type === "store")
  )?.links;

  return (
    <div ref={formRef} className={style.root}>
      <div className={style.stepTitleContainer}>
        <div className={style.step} onClick={() => ScrollToStep(stepOneRef)}>
          <FormattedMessage
            id="no-product-code.step-one"
            defaultMessage="1. Download app"
            description="Title of step one of no product code instructions"
          />
        </div>
        <div className={style.stepTwo} onClick={() => ScrollToStep(stepTwoRef)}>
          <FormattedMessage
            id="no-product-code.step-two"
            defaultMessage="2. Login or register"
            description="Title of step two of no product code instructions"
          />
        </div>
        <div className={style.step} onClick={() => ScrollToStep(stepThreeRef)}>
          <FormattedMessage
            id="no-product-code.step-three"
            defaultMessage="3. Select product and complete registration"
            description="Title of step three of no product code instructions"
          />
        </div>
      </div>

      <div className={style.benefitContentContainer}>
        <div className={style.contentContainer}>
          <div className={style.title}>
            <FormattedMessage
              id="no-product-code.title"
              defaultMessage="This is how it works"
              description="Title of no product code page"
            />
          </div>
          <div ref={stepOneRef} className={style.subTitle}>
            <FormattedMessage
              id="no-product-code.step-one.title"
              defaultMessage="1. Download BWT Best Water Home App"
              description="Step one title of no product code page"
            />
          </div>
          <div className={style.storeLinks}>
            {storeLinks?.map((link, idx) => (
              <div key={idx} className={style.appStore}>
                <StoreLink link={link} />
              </div>
            ))}
          </div>
          <div ref={stepTwoRef} className={style.subTitle}>
            <FormattedMessage
              id="no-product-code.step-two.title"
              defaultMessage="2. Log in or register as a customer"
              description="Step two title of no product code page"
            />
          </div>
          <div className={style.body}>
            <div
              className={style.imgLogin}
              style={{
                backgroundImage: isGerman
                  ? `url(${srcAppLoginDE})`
                  : `url(${srcAppLoginEN})`,
              }}
            />
            <div>
              <FormattedMessage
                id="no-product-code.step-two.body"
                defaultMessage="If you have a BWT account, log in with your e-mail address and password. If you don't have a BWT account yet, you can create a BWT account directly."
                description="Step two body of no product code page"
              />
            </div>
          </div>
          <div ref={stepThreeRef} className={style.subTitle}>
            <FormattedMessage
              id="no-product-code.step-three.title"
              defaultMessage="3. Select product and complete registration"
              description="Step three title of no product code page"
            />
          </div>
          <div className={style.body}>
            <div
              className={style.imgNoCode}
              style={{
                backgroundImage: isGerman
                  ? `url(${srcAppNoCodeDE})`
                  : `url(${srcAppNoCodeEN})`,
              }}
            />
            <div>
              <FormattedMessage
                id="no-product-code.step-three.body"
                defaultMessage='To register a product, select "add product" on the app start screen, select "no code" and choose your product from the list and complete the registration with a few click. You will benefit from numerous advantages.'
                description="Step three body of no product code page"
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "50px" }}>
          <Benefits />
        </div>
      </div>
    </div>
  );
};

export default NoProductCode;
