import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames/bind";
import { defineMessages, useIntl } from "react-intl";

import { useForm } from "./FormContext";
import { checkProductCode } from "modules/api/endpoints";

import CircleIndicator from "modules/common/components/CircleIndicator";
import CategoryTitle from "modules/common/components/CategoryTitle";

import style from "./ProductCodeInput.module.css";

const cx = classnames.bind(style);

const messages = defineMessages({
  title: {
    id: "product-code-input.title",
    defaultMessage: "Product Code",
    description: "category title for entering the product code.",
  },
  tooltip: {
    id: "product-code-input.tooltip",
    defaultMessage:
      "You find the registration sticker with an eight-digit code directly on your BWT device.",
    description: "tooltip: where to find product code.",
  },
});
interface CellProps {
  onClick: () => void;
  onChange: (value: string) => void;
  focused: boolean;
  value: string;
  skip: () => void;
  goBack: () => void;
  onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

function blockSpecialCharacters(e: React.KeyboardEvent<HTMLInputElement>) {
  const regExp = /[a-zA-Z0-9]/;
  if (!e.key.match(regExp)) {
    e.preventDefault();
  }
}

function Cell({
  onClick,
  onChange,
  focused,
  value,
  skip,
  goBack,
  onPaste,
}: CellProps) {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!focused) return;
    ref.current?.focus();
    ref.current?.select();
  }, [focused]);

  return (
    <input
      onPaste={onPaste}
      value={value}
      onClick={onClick}
      ref={ref}
      className={cx("input", { focused })}
      onChange={(e) => onChange(e.target.value)}
      maxLength={1}
      onKeyDown={(e) => {
        e.persist();
        blockSpecialCharacters(e);
        // tab
        if (e.keyCode === 9) {
          // & shift
          if (e.shiftKey) goBack();
          else skip();
        }
        // right arrow
        else if (e.keyCode === 39) skip();
        // left arrow
        else if (e.keyCode === 37) goBack();
        // backspace
        else if (e.keyCode === 8) {
          onChange("");
          goBack();
        }
      }}
    />
  );
}

function range(start: number, finish: number): number[] {
  return Array.from({ length: finish - start }, (_, idx) => idx + start);
}

interface ProductCodeInputState {
  chars: string[];
  focusedIdx: number;
  isBusy: boolean;
}

function renderCell(
  idx: number,
  state: ProductCodeInputState,
  setState: React.Dispatch<React.SetStateAction<ProductCodeInputState>>
): JSX.Element {
  return (
    <Cell
      key={idx}
      value={state.chars[idx]}
      focused={idx === state?.focusedIdx}
      onClick={() => setState({ ...state, focusedIdx: idx })}
      onChange={(value: string) => {
        const regExp = new RegExp(`^[a-zA-Z0-9]`);
        if (value.match(regExp)) {
          const chars = [...state.chars];
          chars[idx] = value.toUpperCase();
          setState({
            isBusy: false,
            chars,
            focusedIdx: idx + 1,
          });
        } else {
          const chars = [...state.chars];
          chars[idx] = "";
          setState({
            isBusy: false,
            chars,
            focusedIdx: idx,
          });
        }
      }}
      skip={() => {
        const newIdx = idx === 7 ? 0 : idx + 1;
        setState({ ...state, focusedIdx: newIdx });
      }}
      goBack={() => {
        const newIdx = idx === 0 ? 7 : idx - 1;
        setState({ ...state, focusedIdx: newIdx });
      }}
      onPaste={(e) => {
        const productCode = e.clipboardData.getData("Text");
        const regExp = new RegExp(`^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$`);
        if (productCode.match(regExp)) {
          const array = productCode.split("");
          const chars = array.filter((char) => char !== "-");
          setState({ isBusy: false, chars, focusedIdx: 7 });
        }
      }}
    />
  );
}

interface ProductCodeInputProps {
  setLastErrorCode: React.Dispatch<React.SetStateAction<null>>;
}
export default function ProductCodeInput({
  setLastErrorCode,
}: ProductCodeInputProps) {
  const { state: formState, setInState } = useForm();
  const { formatMessage } = useIntl();
  const [state, setState] = useState<ProductCodeInputState>({
    chars: ["", "", "", "", "", "", "", ""],
    focusedIdx: -1,
    isBusy: false,
  });
  const full = state?.chars.join("");
  const productCode = full.slice(0, 4) + "-" + full.slice(4);
  const validLength = productCode.length === 9;

  const lastProductResponseData = formState?.lastProductResponse?.data;

  useEffect(() => {
    const runEffect = async () => {
      if (validLength) {
        setInState("form", "productCode", productCode);
        setState((state) => ({ ...state, isBusy: true }));
        const { success, data, errorCode } = await checkProductCode(
          productCode
        );
        if (success) {
          if (data) {
            setInState("lastProductResponse", "data", data);
            setLastErrorCode(null);
          }
        } else {
          if (errorCode) setLastErrorCode(errorCode);
        }
        setState((state) => ({ ...state, isBusy: false }));
      } else {
        setInState("form", "productCode", undefined);
        setInState("lastProductResponse", "data", undefined);
        setLastErrorCode(null);
      }
    };
    runEffect();
  }, [productCode, setInState, validLength, setLastErrorCode]);

  const errored = validLength && !lastProductResponseData && !state.isBusy;

  return (
    <div>
      <div className={style.title}>
        <CategoryTitle
          title={formatMessage(messages.title)}
          message={formatMessage(messages.tooltip)}
        />
      </div>
      <div className={style.productCode}>
        <div className={style.inputs}>
          <div
            className={cx(style.chunk, {
              errored,
              ok: !!lastProductResponseData,
            })}
          >
            {range(0, 4).map((idx) => renderCell(idx, state, setState))}
          </div>
          <div className={style.divide} />
          <div
            className={cx(style.chunk, {
              errored,
              ok: !!lastProductResponseData,
            })}
          >
            {range(4, 8).map((idx) => renderCell(idx, state, setState))}
          </div>
          <div className={style.ci}>
            <CircleIndicator
              show={validLength}
              completed={!!lastProductResponseData}
              errored={errored}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
