import { useIntl, defineMessages } from "react-intl";

import srcApp from "assets/app.png";
import srcCamp from "assets/camp.png";
import srcShop from "assets/shop.png";

export type LinkType = {
  type: string;
  name: string;
  url: string;
};

export type ExternalLinkItemType = {
  img: string;
  title: string;
  desc: string;
  links: LinkType[];
};

export type ExternalLinkItems = ExternalLinkItemType[];

const messages = defineMessages({
  appTitle: {
    id: "external-links.to-app.title",
    defaultMessage: "BWT Best Water Home App",
    description: "title: BWT Best Water Home App",
  },
  appDesc: {
    id: "external-links.to-app.description",
    defaultMessage:
      "Smart: The latest news about your BWT systems are only a tap away.",
    description: "text explaining how to download BWT apps.",
  },
  benefitsTitle: {
    id: "external-links.to-benefits.title",
    defaultMessage: "BWT World of advantages",
    description: "title: perks of BWT online.",
  },
  benefitsDesc: {
    id: "external-links.to-benefits.description",
    defaultMessage:
      "The BWT world of benefits offers you attractive offers and additional services.",
    description: "text explaining the additional online services of BWT.",
  },
  benefitsButton: {
    id: "external-links.to-benefits.button",
    defaultMessage: "Get your benefits now",
    description: "button: link to benefits.",
  },
  shopTitle: {
    id: "external-links.to-shop.title",
    defaultMessage: "BWT Onlineshop",
    description: "title: BWT online shop.",
  },
  shopDesc: {
    id: "external-links.to-shop.description",
    defaultMessage:
      "Now you can easily buy BWT products online with just a few clicks.",
    description: "text explaining how to access the shop.",
  },
  shopButton: {
    id: "external-links.to-shop.button",
    defaultMessage: "Order online now",
    description: "button: link to online shop.",
  },
});

// copied from at-home cms.
const shopLinks: { [locale: string]: string } = {
  "de-AT": "https://l.bwt.com/2d",
  "de-DE": "https://l.bwt.com/2c",
  "de-CH": "https://l.bwt.com/1l",
  "fr-CH": "https://l.bwt.com/1m",
  "fr-FR": "https://l.bwt.com/3p",
  "en-GB": "https://l.bwt.com/42",
  "it-IT": "https://l.bwt.com/51",
  "da-DK": "https://l.bwt.com/52",
  "hu-HU": "https://l.bwt.com/5d",
};

function getShopLink(locale: string): string {
  if (shopLinks[locale]) return shopLinks[locale];

  const countryCode = locale.split("-")[1];
  const preferredLanguage = locale.split("-")[0];
  let fallback = null;

  Object.keys(shopLinks).forEach((key) => {
    if (key.includes(countryCode)) {
      fallback = key;
      return;
    }
  });

  if (!fallback) {
    Object.keys(shopLinks).forEach((key) => {
      if (key.includes(preferredLanguage)) {
        fallback = key;
        return;
      }
    });
  }

  if (!fallback) {
    if (shopLinks[countryCode]) fallback = countryCode;
    if (shopLinks["de-AT"]) fallback = "de-AT";
    if (shopLinks["AT"]) fallback = "AT";
  }

  return fallback ? shopLinks[fallback] : Object.keys(shopLinks)[0];
}

export const useExternalLinkItems = (): ExternalLinkItems => {
  const { formatMessage, locale } = useIntl();
  const shopLink = getShopLink(locale);

  return [
    {
      img: srcApp,
      title: formatMessage(messages.appTitle),
      desc: formatMessage(messages.appDesc),
      links: [
        {
          type: "store",
          name: "apple",
          url:
            "https://apps.apple.com/us/app/bwt-best-water-home/id1317692659?ls=1",
        },
        {
          type: "store",
          name: "google",
          url:
            "https://play.google.com/store/apps/details?id=com.bwt.athomeapp",
        },
      ],
    },
    {
      img: srcCamp,
      title: formatMessage(messages.benefitsTitle),
      desc: formatMessage(messages.benefitsDesc),
      links: [
        {
          type: "button",
          name: formatMessage(messages.benefitsButton),
          url: "",
        },
      ],
    },
    {
      img: srcShop,
      title: formatMessage(messages.shopTitle),
      desc: formatMessage(messages.shopDesc),
      links: [
        {
          type: "button",
          name: formatMessage(messages.shopButton),
          url: shopLink,
        },
      ],
    },
  ];
};
