import React from "react";
import classnames from "classnames/bind";
import { useLocation } from "react-router";

import style from "./Page.module.css";
const cx = classnames.bind(style);

const extraTopPaddingRoutes = [
  "/result",
  "/iot/activation",
  "/iot/cancellation",
];

const Page: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const extraTopPadding = extraTopPaddingRoutes.some((route) =>
    pathname.includes(route)
  );

  return (
    <div
      className={cx(style.root, style.padTop, {
        padTopLarge: extraTopPadding,
      })}
    >
      {children}
    </div>
  );
};

export default Page;
