import React, { Fragment } from "react";

import style from "./Tooltip.module.css";

interface TooltipProps {
  message: string;
  show: boolean;
}

const Tooltip = ({ message, show }: TooltipProps) => {
  if (!show) return <Fragment />;
  return <div className={style.root}>{message}</div>;
};

export default Tooltip;
