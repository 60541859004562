import React from "react";

import style from "./SubHeading.module.css";

interface SubHeadingProps {
  message: string;
}

const SubHeading = ({ message }: SubHeadingProps) => (
  <div className={style.root}>{message}</div>
);

export default SubHeading;
