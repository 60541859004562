import de from "./de.json";
import fr from "./fr.json";
import it from "./it.json";
import nl from "./nl.json";
import da from "./da.json";
import hu from "./hu.json";
import pl from "./pl.json";
import ua from "./ua.json";
import ru from "./ru.json";
import se from "./se.json";
import no from "./no.json";
import fi from "./fi.json";
import es from "./es.json";

export default { de, fr, it, nl, da, hu, pl, ua, ru, se, no, fi, es };
