import React from "react";
import { defineMessages, useIntl } from "react-intl";

import { useForm } from "./FormContext";

import CheckBox from "modules/common/components/CheckBox";

import style from "./AgreeEmailContact.module.css";

const messages = defineMessages({
  label: {
    id: "agree-email-contact.check-box.label",
    defaultMessage:
      "Yes, please inform me by e-mail about necessary maintenance measures on my BWT device. I can cancel my participation at any time.",
    description: "label for accepting email contact check box.",
  },
});

const AgreeEmailContact = () => {
  const { state, setInState } = useForm();
  const { formatMessage } = useIntl();
  return (
    <div className={style.root}>
      <CheckBox
        label={formatMessage(messages.label)}
        checked={state.form.agreeEmailContact ?? false}
        onClick={(agreeEmailContact) =>
          setInState("form", "agreeEmailContact", agreeEmailContact)
        }
      />
    </div>
  );
};

export default AgreeEmailContact;
