import React, { useEffect, Fragment } from "react";

import { usePopUp } from "./PopUpProvider";

const PopUpContent: React.FC<{
  isShowing: boolean;
  showClose: boolean;
  hide: () => void;
}> = ({ isShowing, showClose = true, hide, children }) => {
  const { display } = usePopUp();
  useEffect(
    () => {
      if (isShowing) display(children, showClose, hide);
      else display(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isShowing, children, hide]
  );

  return <Fragment />;
};

export default PopUpContent;
