import React from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";

import Button from "modules/common/components/Button";

import style from "./PlumberOrProfessional.module.css";

const messages = defineMessages({
  button: {
    id: "plumber-or-professional.button",
    defaultMessage: "Continue to the BWT Pro Portal",
    description: "button: link to pro.bwt.com",
  },
});

const PlumberOrProfessional: React.FC<{}> = () => {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  return (
    <div className={style.root}>
      <div className={style.title}>
        <FormattedMessage
          id="plumber-or-professional.title"
          defaultMessage="Are you are plumber or BWT drinking water professional?"
          description="Title of plumber or professional section"
        />
      </div>
      <div className={style.body}>
        <FormattedMessage
          id="plumber-or-professional.body"
          defaultMessage="Please register the products in the BWT Pro Portal."
          description="Body of plumber or professional section"
        />
      </div>
      <Button
        name={formatMessage(messages.button)}
        btnStyle="secondary"
        size="tiny-wide"
        onClick={() => (window.location.href = "https://pro.bwt.com")}
      />
    </div>
  );
};

export default PlumberOrProfessional;
