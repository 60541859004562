import React, { useEffect, useState, useCallback } from "react";
import { useIntl, defineMessages } from "react-intl";
import { useHistory } from "react-router";

import config from "config";
import { useAuth } from "modules/auth/components/AuthProvider";
import { useForm } from "../FormContext";
import { validateDeviceDetails } from "../../data/validation";
import { useLocationQuery } from "modules/util/location";
import {
  approveDeviceActivationRequest,
  getDeviceActivationRequest,
} from "modules/api/endpoints";

import Button from "modules/common/components/Button";
import ProductLocation from "../ProductLocation";
import ShareDataWithPartner from "../ShareDataWithPartner";
import AgreeDataProcessing, {
  AgreeDataProcessingOverlay,
} from "./AgreeDataProcessing";
import AgreeStatusUpdates from "./AgreeStatusUpdates";
import Heading from "modules/common/components/Heading";
import SubHeading from "modules/common/components/SubHeading";
import DeviceImage from "./DeviceImage";
import Error from "modules/common/components/Error";
import ReRegisterOverlay from "./ReRegisterOverlay";

import style from "./DeviceRegistration.module.css";
import DisplayName from "../DisplayName";
import PopUpContent from "modules/common/components/popUp/PopUpContent";
import PageIsLoading from "modules/layout/components/PageIsLoading";

const messages = defineMessages({
  title: {
    id: "device-registration.heading.active",
    defaultMessage: "Activate your BWT product",
    description: "title: IoT device registration.",
  },
  subtitle: {
    id: "device-registration.sub-heading.entry",
    defaultMessage: "Your entry into the service world of BWT!",
    description: "sub title: device activation.",
  },
  nameTitle: {
    id: "device-registration.form.sub-heading.device-name",
    defaultMessage: "Choose a name for your device",
    description: "sub title: set IoT device name.",
  },
  button: {
    id: "device-registration.form.button.complete",
    defaultMessage: "Complete registration",
    description: "button: complete device registration.",
  },
});

async function getCachedRequestForId(requestId: string) {
  await null;
  return localStorage.getItem(requestId);
}

export default function DeviceRegistration() {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();
  const { push } = useHistory();
  const { requestId } = useLocationQuery();
  const {
    state: { isLoggedIn },
    setAuth,
  } = useAuth();
  const [
    {
      sendIsBusy,
      fetchIsBusy,
      showSendError,
      showFetchError,
      lastErrorCode,
      overlay: { show, name },
      hydrated,
    },
    setState,
  ] = useState({
    sendIsBusy: false,
    fetchIsBusy: true,
    showSendError: false,
    showFetchError: false,
    lastErrorCode: null,
    overlay: { show: false, name: "" },
    hydrated: false,
  });

  const { isRegistered, deviceType, deviceVersion } =
    state.lastDeviceResponse?.data || {};

  const properties = {
    requestId: requestId ?? "",
    deviceDisplayName: state.form?.displayName ?? "",
    acceptTerms: state.iot?.agreeDataProcessing ?? false,
    allowSendData: state.iot?.agreeStatusUpdates ?? false,
    shareDeviceWithPlumber: state.form.shareDataWithPartner,
    street: state.form.street,
    city: state.form.city,
    postalCode: state.form.postCode,
    countryCode: state.form.country,
  };

  function hide() {
    setState((state) => ({
      ...state,
      overlay: { show: false, name: "" },
    }));
  }

  const callApi = useCallback(async () => {
    setState((state) => ({
      ...state,
      sendIsBusy: true,
      showSendError: false,
    }));

    const {
      success,
      errorCode,
      unauthorized,
    } = await approveDeviceActivationRequest(properties);

    if (isRegistered) {
      hide();
    }

    if (success) {
      if (requestId) {
        localStorage.removeItem(requestId);
      }
      push("/result");
    } else {
      setState((state) => ({
        ...state,
        showSendError: true,
        lastErrorCode: errorCode,
      }));
      if (unauthorized) {
        setAuth({
          isBusy: false,
          isLoggedIn: false,
          profile: undefined,
        });
        push("/redirect");
      }
    }

    setState((state) => ({
      ...state,
      sendIsBusy: false,
    }));
  }, [properties, push]);

  const sendRequest = useCallback(async () => {
    if (!requestId || sendIsBusy) return;

    if (isLoggedIn) {
      if (isRegistered) {
        setState((state) => ({
          ...state,
          overlay: { show: true, name: "re-register-confirmation" },
        }));
        return;
      }
      await callApi();
    } else {
      localStorage.setItem(requestId, JSON.stringify(properties));
      window.location.href =
        config.loginRedirect + "?returnUrl=" + window.location.href;
    }
  }, [sendIsBusy, isLoggedIn, isRegistered, requestId, properties, callApi]);

  useEffect(() => {
    if (!hydrated) return;
    const runEffect = async () => {
      await sendRequest();
    };
    runEffect();
  }, [hydrated]);

  useEffect(() => {
    if (!requestId) return;

    const runEffect = async () => {
      const { success, data, errorCode } = await getDeviceActivationRequest(
        requestId
      );
      if (success) {
        if (data) {
          setInState("lastDeviceResponse", "data", data);
          if (data.address) {
            if (data.address.street) {
              setInState("form", "street", data.address.street);
            }
            if (data.address.city) {
              setInState("form", "city", data.address.city);
            }
            if (data.address.postalCode) {
              setInState("form", "postCode", data.address.postalCode);
            }
            if (data.address.countryCode) {
              setInState("form", "country", data.address.countryCode);
            }
          }
        }
      } else {
        setInState("lastDeviceResponse", "data", undefined);
        setState((state) => ({
          ...state,
          showFetchError: true,
          lastErrorCode: errorCode,
        }));
      }
      setState((state) => ({ ...state, fetchIsBusy: false }));

      // hydrate from localstorage and re-send request.
      const storageItem = await getCachedRequestForId(requestId);
      if (!storageItem) return;
      const cachedFormData = JSON.parse(storageItem);
      if (!cachedFormData) return;

      setInState("form", "displayName", cachedFormData.deviceDisplayName);
      setInState("iot", "agreeDataProcessing", cachedFormData.acceptTerms);
      setInState("iot", "agreeStatusUpdates", cachedFormData.allowSendData);
      setInState(
        "form",
        "shareDataWithPartner",
        cachedFormData.shareDeviceWithPlumber
      );
      setInState("form", "street", cachedFormData.street);
      setInState("form", "city", cachedFormData.city);
      setInState("form", "postCode", cachedFormData.postalCode);
      setInState("form", "country", cachedFormData.countryCode);

      setState((state) => ({ ...state, hydrated: true }));
    };
    runEffect();
  }, [requestId, setInState]);

  function getOverlayComponent(name: string) {
    switch (name) {
      case "agree-data-processing":
        return <AgreeDataProcessingOverlay />;
      case "re-register-confirmation":
        return (
          <ReRegisterOverlay
            onConfirm={async () => {
              await callApi();
            }}
            onReject={() => hide()}
            sendIsBusy={sendIsBusy}
          />
        );
      default:
        return null;
    }
  }

  const showError = showSendError || showFetchError;

  if (fetchIsBusy || (!isRegistered && sendIsBusy)) return <PageIsLoading />;

  return (
    <div className={style.container}>
      <PopUpContent
        isShowing={show}
        hide={hide}
        showClose={name !== "re-register-confirmation" || !sendIsBusy}
      >
        {getOverlayComponent(name)}
      </PopUpContent>
      <div className={style.errorContainer}>
        <Error show={showError} errorCode={lastErrorCode} />
      </div>
      <div className={style.topSeg}>
        <Heading message={formatMessage(messages.title)} />
        <SubHeading message={formatMessage(messages.subtitle)} />
        <DeviceImage type={deviceType ?? ""} version={deviceVersion ?? ""} />
      </div>
      <div className={style.form}>
        <DisplayName />
        <ProductLocation />
        <div className={style.checks}>
          <AgreeDataProcessing
            setShow={() =>
              setState((state) => ({
                ...state,
                overlay: { show: true, name: "agree-data-processing" },
              }))
            }
          />
          <ShareDataWithPartner />
          <AgreeStatusUpdates />
        </div>
        <div className={style.btn}>
          <Button
            name={formatMessage(messages.button)}
            btnStyle="primary"
            onClick={sendRequest}
            disabled={
              !validateDeviceDetails(state) ||
              !requestId ||
              sendIsBusy ||
              showFetchError
            }
          />
        </div>
      </div>
    </div>
  );
}
