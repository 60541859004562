import { defineMessages, useIntl } from "react-intl";
import { useLocation } from "react-router";

import { useForm } from "../components/FormContext";
import { CustomerBenefits, SpecificProductType } from "./types";
import { isDanish } from "modules/util/constants";

import srcApp from "assets/IC_BWT-App.svg";
import srcGuarantee from "assets/IC_Guarantee.svg";
import srcVoucher from "assets/IC_Vouchers-01.svg";
import srcVoucherShop from "assets/IC_Vouchers-02.svg";
import srcMaintenence from "assets/IC_Maintenence.svg";
import srcService from "assets/IC_Service.svg";

const messages = defineMessages({
  voucher_online_shop_body: {
    id: "benefits.voucher_online_shop_body",
    defaultMessage: "Great price advantages for your registered products.",
    description: "product benefit.",
  },
  voucher_online_shop_title: {
    id: "benefits.voucher_online_shop_title",
    defaultMessage: "Vouchers online shop",
    description: "product benefit.",
  },
  ten_year_warranty_body: {
    id: "benefits.ten_year_warranty_body",
    defaultMessage: "Protect your investments with our guarantee.",
    description: "product benefit.",
  },
  ten_year_warranty_title: {
    id: "benefits.ten_year_warranty_title",
    defaultMessage: "10 year guarantee *",
    description: "product benefit.",
  },
  voucher_hygiene_box_body: {
    id: "benefits.voucher_hygiene_box_body",
    defaultMessage:
      "Your E1 hygiene box subscription at a special price. Complete with a voucher code in the BWT online shop.",
    description: "product benefit.",
  },
  voucher_hygiene_box_title: {
    id: "benefits.voucher_hygiene_box_title",
    defaultMessage: "Voucher: hygiene box *",
    description: "product benefit.",
  },
  maintenence_and_information_body: {
    id: "benefits.maintenence_and_information_body",
    defaultMessage:
      "We will inform you about necessary maintenance measures and product innovations.",
    description: "product benefit.",
  },
  maintenence_and_information_title: {
    id: "benefits.maintenence_and_information_title",
    defaultMessage: "Maintenance & information",
    description: "product benefit.",
  },
  service_and_help_body: {
    id: "benefits.service_and_help_body",
    defaultMessage:
      "Our customer service can focus on you and your BWT products.",
    description: "product benefit.",
  },
  service_and_help_title: {
    id: "benefits.service_and_help_title",
    defaultMessage: "Service & help",
    description: "product benefit.",
  },
  best_water_home_app_body: {
    id: "benefits.best_water_home_app_body",
    defaultMessage:
      "You have an overview of your devices at all times and enjoy maximum service.",
    description: "product benefit.",
  },
  best_water_home_app_title: {
    id: "benefits.best_water_home_app_title",
    defaultMessage: "Best Water Home App",
    description: "product benefit.",
  },
  best_water_home_app_jugs_body: {
    id: "benefits.best_water_home_app_jugs_body",
    defaultMessage:
      "The perfect replacement for the manual or electronic timer.",
    description: "product benefit.",
  },
  best_water_home_app_pool_tester_body: {
    id: "benefits.best_water_home_app_pool_tester_body",
    defaultMessage:
      "Enjoy individual pool care advice for the best bathing pleasure and perfect hygiene.",
    description: "product benefit.",
  },
  voucher_pool_agents_body: {
    id: "benefits.voucher_pool_agents_body",
    defaultMessage:
      "Pool active ingredients from the BWT pool care lines at a special price.",
    description: "product benefit.",
  },
  voucher_pool_agents_title: {
    id: "benefits.voucher_pool_agents_title",
    defaultMessage: "Voucher: pool care products",
    description: "product benefit.",
  },
  best_water_home_app_pool_robot_body: {
    id: "benefits.best_water_home_app_pool_robot_body",
    defaultMessage:
      "You have an overview of your devices at all times and enjoy maximum service.",
    description: "product benefit.",
  },
  softener_free_service_body: {
    id: "benefits.free_service_body",
    defaultMessage: "Get a 2 Year Free Service with your softener",
    description: "product benefit.",
  },
  softener_free_service_title: {
    id: "benefits.free_service_title",
    defaultMessage: "2 Years Free Service",
    description: "product benefit.",
  },
});

const defaultBenefits = [
  CustomerBenefits.MaintenanceAndInformation,
  CustomerBenefits.ServiceAndHelp,
  CustomerBenefits.BestWaterHomeApp,
];

// CustomerBenefitsMapping.cs needs updating and should probably handle per language.
const HACK_danishBenefits = [
  CustomerBenefits.MaintenanceAndInformation,
  CustomerBenefits.FreeService,
  CustomerBenefits.BestWaterHomeApp,
];

const HACK_defaultDanishBenefits = [
  CustomerBenefits.MaintenanceAndInformation,
  CustomerBenefits.VoucherOnlineShop,
  CustomerBenefits.BestWaterHomeApp,
];

type Benefit = {
  icon: string;
  heading: string;
  message: string;
};

export type Benefits = {
  benefits: Benefit[];
  displayLegalNote: boolean;
};

export function useBenefits(): Benefits {
  const { state } = useForm();
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  const lastProductResponseData = state?.lastProductResponse?.data;
  const isGeneric =
    lastProductResponseData?.specificProductType ===
    SpecificProductType.Generic;
  const useDanishBenefits = isDanish && isGeneric;
  const hideAS400 = !state.form.contractTerms && pathname.includes("/result");
  const danishBenefits = hideAS400
    ? HACK_defaultDanishBenefits
    : HACK_danishBenefits;

  const benefits = useDanishBenefits
    ? danishBenefits
    : lastProductResponseData?.benefits ?? defaultBenefits;
  const displayLegalNote = lastProductResponseData?.displayLegalNote ?? false;

  const lookupBenefit = (benefit: CustomerBenefits) => {
    switch (benefit) {
      case CustomerBenefits.BestWaterHomeApp: {
        return {
          icon: srcApp,
          heading: formatMessage(messages.best_water_home_app_title),
          message: formatMessage(messages.best_water_home_app_body),
        };
      }
      case CustomerBenefits.BestWaterHomeAppJugs: {
        return {
          icon: srcApp,
          heading: formatMessage(messages.best_water_home_app_title),
          message: formatMessage(messages.best_water_home_app_jugs_body),
        };
      }
      case CustomerBenefits.BestWaterHomeAppPoolRobot: {
        return {
          icon: srcApp,
          heading: formatMessage(messages.best_water_home_app_title),
          message: formatMessage(messages.best_water_home_app_pool_robot_body),
        };
      }
      case CustomerBenefits.BestWaterHomeAppPoolTester: {
        return {
          icon: srcApp,
          heading: formatMessage(messages.best_water_home_app_title),
          message: formatMessage(messages.best_water_home_app_pool_tester_body),
        };
      }
      case CustomerBenefits.MaintenanceAndInformation: {
        return {
          icon: srcMaintenence,
          heading: formatMessage(messages.maintenence_and_information_title),
          message: formatMessage(messages.maintenence_and_information_body),
        };
      }
      case CustomerBenefits.ServiceAndHelp: {
        return {
          icon: srcService,
          heading: formatMessage(messages.service_and_help_title),
          message: formatMessage(messages.service_and_help_body),
        };
      }
      case CustomerBenefits.TenYearWarranty: {
        return {
          icon: srcGuarantee,
          heading: formatMessage(messages.ten_year_warranty_title),
          message: formatMessage(messages.ten_year_warranty_body),
        };
      }
      case CustomerBenefits.VoucherHygieneBox: {
        return {
          icon: srcVoucher,
          heading: formatMessage(messages.voucher_hygiene_box_title),
          message: formatMessage(messages.voucher_hygiene_box_body),
        };
      }
      case CustomerBenefits.VoucherOnlineShop: {
        return {
          icon: srcVoucherShop,
          heading: formatMessage(messages.voucher_online_shop_title),
          message: formatMessage(messages.voucher_online_shop_body),
        };
      }
      case CustomerBenefits.VoucherPoolAgents: {
        return {
          icon: srcVoucherShop,
          heading: formatMessage(messages.voucher_pool_agents_title),
          message: formatMessage(messages.voucher_pool_agents_body),
        };
      }
      case CustomerBenefits.FreeService: {
        return {
          icon: srcGuarantee,
          heading: formatMessage(messages.softener_free_service_title),
          message: formatMessage(messages.softener_free_service_body),
        };
      }
    }
  };

  return {
    benefits: benefits.map((b: CustomerBenefits) => lookupBenefit(b)),
    displayLegalNote,
  };
}
