import React from "react";

import style from "./Heading.module.css";

interface HeadingProps {
  message: string;
}

const Heading = ({ message }: HeadingProps) => (
  <div className={style.root}>{message}</div>
);

export default Heading;
