import React from "react";
import cx from "classnames";

import style from "./LoadingSpinner.module.css";

const LoadingSpinner = () => {
  return (
    <div className={style.root}>
      <div className={style.seg}></div>
      <div className={cx(style.seg, style.seg2)}></div>
      <div className={cx(style.seg, style.seg3)}></div>
      <div className={cx(style.seg, style.seg4)}></div>
      <div className={cx(style.seg, style.seg5)}></div>
      <div className={cx(style.seg, style.seg6)}></div>
      <div className={cx(style.seg, style.seg7)}></div>
      <div className={cx(style.seg, style.seg8)}></div>
      <div className={cx(style.seg, style.seg9)}></div>
      <div className={cx(style.seg, style.seg10)}></div>
      <div className={cx(style.seg, style.seg11)}></div>
      <div className={cx(style.seg, style.seg12)}></div>
    </div>
  );
};

export default LoadingSpinner;
