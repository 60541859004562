import React, { Fragment, useState } from "react";

import Tooltip from "./Tooltip";

import srcInfo from "assets/IC_Info.svg";

import style from "./Info.module.css";

interface InfoIconProps {
  message?: string;
}

const InfoIcon = ({ message }: InfoIconProps) => {
  const [show, setShow] = useState(false);
  if (!message) return <Fragment />;

  return (
    <div className={style.root}>
      <img
        src={srcInfo}
        className={style.icon}
        alt=""
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
      <Tooltip message={message} show={show} />
    </div>
  );
};

export default InfoIcon;
