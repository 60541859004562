import React from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import Button from "modules/common/components/Button";

import srcAccount from "assets/IC_Account_GREY.svg";
import srcAccountPink from "assets/IC_Account.svg";

import style from "./BWTWorld.module.css";

const messages = defineMessages({
  button: {
    id: "bwt-world.button",
    defaultMessage: "switch to BWT Account",
    description: "button: external link to bwt account.",
  },
});

const BWTWorld = () => {
  const { formatMessage } = useIntl();
  return (
    <div className={style.root}>
      <div className={style.title}>
        <FormattedMessage
          id="bwt-world.title"
          defaultMessage="Your BWT World"
          description="title for bwt world section."
        />
      </div>
      <div className={style.text}>
        <FormattedMessage
          id="bwt-world.body-text"
          defaultMessage="With your BWT account you can manage everything in one place. Keep track of your subscriptions and order history, update your privacy and security settings, monitor the functionality and security of your devices, and receive exclusive rewards."
          description="body text for bwt world section."
        />
      </div>
      <a
        className={style.link}
        href="https://account.bwt-group.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          name={formatMessage(messages.button)}
          btnStyle="secondary"
          size="large"
          icons={{
            default: srcAccount,
            hover: srcAccountPink,
          }}
        />
      </a>
    </div>
  );
};

export default BWTWorld;
