import React, { useState } from "react";
import classnames from "classnames/bind";

import style from "./TextInput.module.css";
import CircleIndicator from "./CircleIndicator";
const cx = classnames.bind(style);

interface TextInputProps {
  label: string;
  defaultValue?: string;
  update: (value: string) => void;
  narrow?: boolean;
  disabled?: boolean;
  errorOverride?: boolean;
}

const TextInput = ({
  label,
  defaultValue,
  update,
  narrow = false,
  disabled = false,
  errorOverride = false,
}: TextInputProps) => {
  const [{ focused, hasChanged }, setState] = useState({
    focused: false,
    hasChanged: false,
  });
  const errored = !defaultValue || errorOverride;
  const show = !!defaultValue || hasChanged;
  const showError = show && errored;

  return (
    <div className={style.root}>
      <div
        className={cx(style.seg, {
          narrow,
          disabled,
          focused,
          errored: showError,
        })}
      >
        <div className={style.placeholder}>{label}</div>
        <input
          className={style.input}
          onChange={(e) => {
            setState((state) => ({ ...state, hasChanged: true }));
            update(e.target.value);
          }}
          value={defaultValue}
          autoComplete="off"
          disabled={disabled}
          onFocus={() => setState((state) => ({ ...state, focused: true }))}
          onBlur={() => setState((state) => ({ ...state, focused: false }))}
        />
      </div>
      <div className={style.circle}>
        <CircleIndicator show={show} completed={!errored} errored={errored} />
      </div>
    </div>
  );
};

export default TextInput;
