import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import translations from "i18n/translations";
import countryTranslations from "i18n/countryTranslations";

import { useAuth } from "modules/auth/components/AuthProvider";

type Messages = Record<string, string>;

const ConnectedIntlProvider: React.FC = ({ children }) => {
  // window.LOCALE_PREFIX appears unavaialble on fresh run, get from url.
  const sitecoreLocale = window.LOCALE_PREFIX
    ? window.LOCALE_PREFIX.replace("/", "")
    : window.location.pathname.split("/")[1];
  const initialLocale = sitecoreLocale || navigator.language;

  const [locale, setLocale] = useState(initialLocale);
  const language = locale.split("-")[0];

  const {
    state: { profile },
  } = useAuth();

  useEffect(() => {
    if (profile) {
      if (profile.preferredLanguage && profile.countryCode) {
        const userLocale =
          profile.preferredLanguage + "-" + profile.countryCode;
        setLocale(userLocale);
      }
    }
  }, [profile]);

  const de: Messages = {};
  const fr: Messages = {};
  const it: Messages = {};
  const nl: Messages = {};
  const da: Messages = {};
  const hu: Messages = {};
  const pl: Messages = {};
  const ua: Messages = {};
  const ru: Messages = {};
  const se: Messages = {};
  const no: Messages = {};
  const fi: Messages = {};
  const es: Messages = {};

  const messages: Record<string, Messages> = {
    de,
    fr,
    it,
    nl,
    da,
    hu,
    pl,
    ua,
    ru,
    se,
    no,
    fi,
    es,
  };

  Object.entries(translations).forEach(([code, staticMessages]) => {
    Object.entries(staticMessages).forEach(([path, obj]) => {
      messages[code] = {
        ...messages[code],
        [path]: obj.message || "",
      };
    });
  });

  // add country name translations to app.
  Object.entries(countryTranslations).forEach(([code, staticMessages]) => {
    if (!messages[code]) {
      return;
    }
    Object.entries(staticMessages).forEach(([path, message]) => {
      messages[code] = {
        ...messages[code],
        [path]: message || "",
      };
    });
  });

  return (
    <IntlProvider
      key={locale}
      defaultLocale="en-GB"
      locale={locale}
      messages={messages[language]}
    >
      {children}
    </IntlProvider>
  );
};

export default ConnectedIntlProvider;
