import React from "react";
import { defineMessages, useIntl } from "react-intl";

import { useForm } from "./FormContext";

import InfoIcon from "modules/common/components/Info";
import CheckBox from "modules/common/components/CheckBox";

import style from "./ShareDataWithPartner.module.css";

const messages = defineMessages({
  label: {
    id: "share-data-with-partner.label",
    defaultMessage:
      "I would like to share the device status with my service partner.",
    description:
      "label: select whether user is willing to share data with their partner.",
  },
  tooltip: {
    id: "share-data-with-partner.tooltip",
    defaultMessage:
      "If you add your service partner, they can see messages of your BWT products. This can be helpful for services purposes.",
    description: "tooltip: benefits of adding a service partner.",
  },
});

export default function ShareDataWithPartner() {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();
  return (
    <div className={style.root}>
      <CheckBox
        label={formatMessage(messages.label)}
        checked={state.form.shareDataWithPartner}
        onClick={(shareDataWithPartner: boolean) =>
          setInState("form", "shareDataWithPartner", shareDataWithPartner)
        }
      />
      <InfoIcon message={formatMessage(messages.tooltip)} />
    </div>
  );
}
