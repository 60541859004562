import React from "react";
import { defineMessages, useIntl } from "react-intl";

import { useForm } from "./FormContext";

import CategoryTitle from "modules/common/components/CategoryTitle";

import style from "./PeopleInTheHouse.module.css";

const messages = defineMessages({
  title: {
    id: "people-in-the-house.title",
    defaultMessage: "People in the household",
    description: "category title for entering number of people per household section.",
  },
  description: {
    id: "people-in-the-house.description",
    defaultMessage: "We use this to calculate the salt consumption of your home",
    description: "category description for entering number of people per household section.",
  },
});

export const maxPersonCount = 20;

const PeopleInTheHouse = () => {
  const { state, setInState } = useForm();
  const { formatMessage } = useIntl();

  return (
    <div className={style.root}>
      <CategoryTitle title={formatMessage(messages.title)} />
      <p className={style.label}>{formatMessage(messages.description)}</p>
      <input
        className={style.input}
        onChange={(event) => {
          const value = event.target.value;
          if (!value) {
            setInState("form", "peoplePerHousehold", undefined);
            return;
          }
          const valueAsInt = parseInt(value);
          if (valueAsInt > maxPersonCount) {
            setInState("form", "peoplePerHousehold", 20);
          } else if (valueAsInt < 0) {
            setInState("form", "peoplePerHousehold", 0);
          } else {
            setInState("form", "peoplePerHousehold", valueAsInt);
          }
        }}
        onKeyDown={(e) => {
          e.persist();
          if (e.key === "-" || e.key === "+") e.preventDefault();
        }}
        type="number"
        pattern="[0-9]*"
        max={maxPersonCount}
        value={state.form.peoplePerHousehold ?? ""}
      />
    </div>
  );
};

export default PeopleInTheHouse;
