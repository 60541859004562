import { FormContextState } from "../components/FormContext";
import { ProductType } from "./types";
import { getPostcodeRegex } from "modules/util/regexp";
import { maxPersonCount } from "../components/PeopleInTheHouse";

export function validateNumber(value?: number) {
  if (value === undefined) return false;
  if (!isFinite(value)) return false;
  return true;
}

export function validatePostCode(
  postCode: string,
  countryCode: string
): boolean {
  const countryRegex = getPostcodeRegex(countryCode);
  if (!countryRegex) return true;
  return countryRegex.test(postCode);
}

function validateAddress(state: FormContextState) {
  if (!state.form.street) return false;
  if (!state.form.postCode) return false;
  if (!state.form.city) return false;
  if (!state.form.country) return false;

  const validPostCode = validatePostCode(
    state.form.postCode,
    state.form.country
  );
  if (!validPostCode) return false;

  return true;
}

export function validateDeviceDetails(state: FormContextState) {
  const validAddress = validateAddress(state);
  if (!validAddress) return false;
  if (!state.iot?.agreeDataProcessing) return false;
  if (!state.form.displayName) return false;
  return true;
}

export function validateProductDetails(state: FormContextState) {
  const { productType } = state?.lastProductResponse?.data || {};

  const validAddress = validateAddress(state);
  if (!validAddress) return false;

  switch (productType) {
    case ProductType.Default: {
      if (!state.form.displayName) return false;
      const personCount = state.form.peoplePerHousehold ?? 0;
      if (personCount > maxPersonCount) return false;
      return true;
    }
    case ProductType.Pool: {
      if (!state.pool) return false;
      if (!state.form.poolId) {
        if (!state.pool.name) return false;
        if (!state.pool.volume) {
          if (!state.pool.length) return false;
          if (!state.pool.width) return false;
          if (!state.pool.depth) return false;
        }
      }
      return true;
    }
    case ProductType.WaterDispenser: {
      if (!state.drinkPro20) return false;
      const dailyWater = state.drinkPro20.dailyWaterUsage;
      const dailySoda = state.drinkPro20.dailySodaUsage;
      if (!dailyWater) return false;
      if (!dailySoda) return false;
      if (dailySoda > dailyWater) return false;
      if (!state.drinkPro20.volume) {
        if (!state.drinkPro20.height) return false;
        if (!state.drinkPro20.width) return false;
      }
      if (!state.drinkPro20.currentCartridge) return false;
      if (!state.drinkPro20.installedCO2Bottle) return false;
      if (!state.form.displayName) return false;
      return true;
    }
    default:
      return false;
  }
}
