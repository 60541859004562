import React, { useState, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";

import { useForm } from "../FormContext";
import { fetchPools } from "modules/api/endpoints";

import CreatePoolForm from "./CreatePoolForm";
import CategoryTitle from "modules/common/components/CategoryTitle";
import Button from "modules/common/components/Button";
import CheckBox from "modules/common/components/CheckBox";

import srcPool from "assets/IC_Pool.svg";

import style from "./PoolForm.module.css";
import { Pool, Pools } from "modules/form/data/types";
import LoadingSpinner from "modules/common/components/LoadingSpinner";

const messages = defineMessages({
  title: {
    id: "pool-form.title.select-pool",
    defaultMessage: "Pool Location",
    description: "title: for selecting which pool.",
  },
  button: {
    id: "pool-form.button.create-new-pool",
    defaultMessage: "Create pool",
    description: "button: takes user to create new pool form.",
  },
});

interface PoolFormState {
  creativeMode: boolean;
  pools: Pools;
  isBusy: boolean;
}

const PoolForm = () => {
  const { formatMessage } = useIntl();
  const { state: formState, setInState } = useForm();
  const [state, setState] = useState<PoolFormState>({
    creativeMode: false,
    pools: [],
    isBusy: false,
  });

  useEffect(() => {
    const runEffect = async () => {
      setState((state) => ({ ...state, isBusy: true }));
      const { success, data } = await fetchPools();
      if (success) {
        if (data) {
          setState((state) => ({ ...state, pools: data }));
        }
      }
      setState((state) => ({ ...state, isBusy: false }));
    };
    runEffect();
  }, []);

  if (state.isBusy) return <LoadingSpinner />;

  const noPoolsToShow = !state.pools || state.pools.length === 0;
  if (state.creativeMode || noPoolsToShow)
    return (
      <CreatePoolForm
        goBack={() => setState((state) => ({ ...state, creativeMode: false }))}
        showGoBack={!noPoolsToShow}
      />
    );

  return (
    <div className={style.root}>
      <CategoryTitle title={formatMessage(messages.title)} />
      <img src={srcPool} className={style.img} alt="" />
      <div className={style.pools}>
        {state.pools?.map((pool: Pool, idx) => (
          <div key={idx} className={style.item}>
            <CheckBox
              label={pool.name}
              onClick={() => setInState("form", "poolId", pool.id)}
              checked={pool.id === formState.form.poolId}
            />
          </div>
        ))}
      </div>
      <Button
        name={formatMessage(messages.button)}
        btnStyle="secondary"
        onClick={() => {
          setInState("form", "poolId", undefined);
          setState({ ...state, creativeMode: true });
        }}
      />
    </div>
  );
};

export default PoolForm;
