import React, { useState, useCallback, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";

import { useLocationQuery } from "modules/util/location";
import {
  declineDeviceActivationRequest,
  getDeviceActivationRequest,
} from "modules/api/endpoints";
import { useForm } from "../FormContext";
import { useAuth } from "modules/auth/components/AuthProvider";

import Heading from "modules/common/components/Heading";
import SubHeading from "modules/common/components/SubHeading";
import Button from "modules/common/components/Button";
import Benefits from "../Benefits";
import DeviceImage from "./DeviceImage";
import Error from "modules/common/components/Error";
import PageIsLoading from "modules/layout/components/PageIsLoading";

import style from "./DeviceCancellation.module.css";

const messages = defineMessages({
  title: {
    id: "device-cancellation.title",
    defaultMessage: "Cancel registration",
    description: "title: cancel device registration.",
  },
  subtitle: {
    id: "device-cancellation.sub-title",
    defaultMessage:
      "Are you sure that you don't want to register your BWT device?",
    description: "subtitle: cancel device registration.",
  },
  primaryBtn: {
    id: "device-cancellation.button.cancel",
    defaultMessage: "Yes, I want to cancel the registration",
    description: "button: confirmation - cancel device registration.",
  },
  secondaryBtn: {
    id: "device-cancellation.button.continue",
    defaultMessage: "Continue registration",
    description: "button: continue device registration flow.",
  },
});

export default function DeviceCancellation() {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();
  const { push } = useHistory();
  const { requestId } = useLocationQuery();
  const { setAuth } = useAuth();
  const [
    { sendIsBusy, fetchIsBusy, showSendError, showFetchError, lastErrorCode },
    setState,
  ] = useState({
    sendIsBusy: false,
    fetchIsBusy: true,
    showSendError: false,
    showFetchError: false,
    lastErrorCode: null,
  });

  const sendRequest = useCallback(async () => {
    if (!requestId || sendIsBusy) return;
    setState((state) => ({
      ...state,
      sendIsBusy: true,
      showSendError: false,
      lastErrorCode: null,
    }));
    const {
      success,
      errorCode,
      unauthorized,
    } = await declineDeviceActivationRequest(requestId);
    if (success) {
      push("/");
    } else {
      setState((state) => ({
        ...state,
        showSendError: true,
        lastErrorCode: errorCode,
      }));
      if (unauthorized) {
        setAuth({
          isBusy: false,
          isLoggedIn: false,
          profile: undefined,
        });
        push("/redirect");
      }
    }
    setState((state) => ({ ...state, sendIsBusy: false }));
  }, [sendIsBusy, requestId, push]);

  useEffect(() => {
    if (!requestId) return;

    const runEffect = async () => {
      const { success, data, errorCode } = await getDeviceActivationRequest(
        requestId
      );
      if (success) {
        if (data) {
          setInState("lastDeviceResponse", "data", data);
        }
      } else {
        setInState("lastDeviceResponse", "data", undefined);
        setState((state) => ({
          ...state,
          showFetchError: true,
          lastErrorCode: errorCode,
        }));
      }
      setState((state) => ({ ...state, fetchIsBusy: false }));
    };
    runEffect();
  }, [requestId, setInState]);

  const { deviceType, deviceVersion } = state.lastDeviceResponse?.data || {};

  if (fetchIsBusy) return <PageIsLoading />;

  const showError = showFetchError || showSendError;

  return (
    <div>
      <Error show={showError} errorCode={lastErrorCode} />
      <div className={style.root}>
        <Heading message={formatMessage(messages.title)} />
        <SubHeading message={formatMessage(messages.subtitle)} />
        <div className={style.main}>
          <DeviceImage type={deviceType ?? ""} version={deviceVersion ?? ""} />
          <Benefits />
        </div>
        <div className={style.btns}>
          <div className={style.btnPrim}>
            <Button
              name={formatMessage(messages.primaryBtn)}
              btnStyle="primary"
              onClick={sendRequest}
              disabled={
                !requestId || fetchIsBusy || sendIsBusy || showFetchError
              }
            />
          </div>
          <div className={style.btnSec}>
            <Button
              name={formatMessage(messages.secondaryBtn)}
              btnStyle="secondary"
              onClick={() => push(`/iot/activation?requestId=${requestId}`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
