interface CountryPostcodeRegex {
  countryCode: string;
  regExp: RegExp;
}

export const getPostcodeRegex = (country?: string) => {
  if (!country) return;
  return postCodeRegex.find((val) => val.countryCode === country)?.regExp;
};

export const postCodeRegex: CountryPostcodeRegex[] = [
  {
    countryCode: "GB",
    regExp: new RegExp(
      "GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\\d{1,4}$"
    ),
  },
  {
    countryCode: "JE",
    regExp: RegExp("JE\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}$"),
  },
  {
    countryCode: "GG",
    regExp: RegExp("GY\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}$"),
  },
  {
    countryCode: "IM",
    regExp: RegExp("IM\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}$"),
  },
  { countryCode: "US", regExp: RegExp("\\d{5}([ -]\\d{4})?$") },
  {
    countryCode: "CA",
    regExp: RegExp(
      "[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d"
    ),
  },
  { countryCode: "DE", regExp: RegExp("^\\d{5}$") },
  { countryCode: "JP", regExp: RegExp("\\d{3}-\\d{4}$") },
  { countryCode: "FR", regExp: RegExp("^(?:[0-8]\\d|9[0-8])\\d{3}$") },
  { countryCode: "AU", regExp: RegExp("^\\d{4}$") },
  { countryCode: "IT", regExp: RegExp("^\\d{5}$") },
  { countryCode: "CH", regExp: RegExp("^\\d{4}$") },
  { countryCode: "AT", regExp: RegExp("^\\d{4}$") },
  { countryCode: "ES", regExp: RegExp("^\\d{5}$") },
  { countryCode: "NL", regExp: RegExp("\\d{4}\\s*([A-Z]{2})$") },
  { countryCode: "BE", regExp: RegExp("^\\d{4}$") },
  { countryCode: "DK", regExp: RegExp("^([D-d][K-k]( |-))?[1-9]{1}[0-9]{3}$") },
  { countryCode: "SE", regExp: RegExp("^\\d{3}[ ]?\\d{2}$") },
  { countryCode: "NO", regExp: RegExp("^\\d{4}$") },
  { countryCode: "BR", regExp: RegExp("\\d{5}[-]?\\d{3}$") },
  { countryCode: "PT", regExp: RegExp("\\d{4}([-]\\d{3})?$") },
  { countryCode: "FI", regExp: RegExp("^\\d{5}$") },
  { countryCode: "AX", regExp: RegExp("\\22d{3}$") },
  { countryCode: "KR", regExp: RegExp("\\d{3}[-]\\d{3}$") },
  { countryCode: "CN", regExp: RegExp("^\\d{6}$") },
  { countryCode: "TW", regExp: RegExp("\\d{3}(\\d{2})?$") },
  { countryCode: "SG", regExp: RegExp("\\d{6}$") },
  { countryCode: "DZ", regExp: RegExp("\\d{5}$") },
  { countryCode: "AD", regExp: RegExp("AD\\d{3}$") },
  { countryCode: "AR", regExp: RegExp("([A-HJ-NP-Z])?\\d{4}([A-Z]{3})?$") },
  { countryCode: "AM", regExp: RegExp("(37)?\\d{4}$") },
  { countryCode: "AZ", regExp: RegExp("\\d{4}$") },
  { countryCode: "BH", regExp: RegExp("((1[0-2]|[2-9])\\d{2})?$") },
  { countryCode: "BD", regExp: RegExp("\\d{4}$") },
  { countryCode: "BB", regExp: RegExp("(BB\\d{5})?$") },
  { countryCode: "BY", regExp: RegExp("\\d{6}$") },
  { countryCode: "BM", regExp: RegExp("[A-Z]{2}[ ]?[A-Z0-9]{2}$") },
  { countryCode: "BA", regExp: RegExp("\\d{5}$") },
  { countryCode: "IO", regExp: RegExp("BBND 1ZZ$") },
  { countryCode: "BN", regExp: RegExp("[A-Z]{2}[ ]?\\d{4}$") },
  { countryCode: "BG", regExp: RegExp("\\d{4}$") },
  { countryCode: "KH", regExp: RegExp("\\d{5}$") },
  { countryCode: "CV", regExp: RegExp("\\d{4}$") },
  { countryCode: "CL", regExp: RegExp("\\d{7}$") },
  { countryCode: "CR", regExp: RegExp("\\d{4,5}|d{3}-d{4}$") },
  { countryCode: "HR", regExp: RegExp("\\d{5}$") },
  { countryCode: "CY", regExp: RegExp("\\d{4}$") },
  { countryCode: "CZ", regExp: RegExp("\\d{3}[ ]?d{2}$") },
  { countryCode: "DO", regExp: RegExp("\\d{5}$") },
  {
    countryCode: "EC",
    regExp: RegExp("([A-Z]\\d{4}[A-Z]|(?:[A-Z]{2})?\\d{6})?$"),
  },
  { countryCode: "EG", regExp: RegExp("\\d{5}$") },
  { countryCode: "EE", regExp: RegExp("\\d{5}$") },
  { countryCode: "FO", regExp: RegExp("\\d{3}$") },
  { countryCode: "GE", regExp: RegExp("\\d{4}$") },
  { countryCode: "GR", regExp: RegExp("\\d{3}[ ]?\\d{2}$") },
  { countryCode: "GL", regExp: RegExp("\\39d{2}$") },
  { countryCode: "GT", regExp: RegExp("\\d{5}$") },
  { countryCode: "HT", regExp: RegExp("\\d{4}$") },
  { countryCode: "HN", regExp: RegExp("(?:\\d{5})?$") },
  { countryCode: "HU", regExp: RegExp("^\\d{4}$") },
  { countryCode: "IS", regExp: RegExp("\\d{3}$") },
  { countryCode: "IN", regExp: RegExp("\\d{6}$") },
  { countryCode: "ID", regExp: RegExp("\\d{5}$") },
  { countryCode: "IL", regExp: RegExp("\\d{5}$") },
  { countryCode: "JO", regExp: RegExp("\\d{5}$") },
  { countryCode: "KZ", regExp: RegExp("\\d{6}$") },
  { countryCode: "KE", regExp: RegExp("\\d{5}$") },
  { countryCode: "KW", regExp: RegExp("\\d{5}$") },
  { countryCode: "LA", regExp: RegExp("\\d{5}$") },
  { countryCode: "LV", regExp: RegExp("\\d{4}$") },
  { countryCode: "LB", regExp: RegExp("(\\d{4}([ ]?\\d{4})?)?$") },
  { countryCode: "LI", regExp: RegExp("(948[5-9])|(949[0-7])$") },
  { countryCode: "LT", regExp: RegExp("^\\d{5}$") },
  { countryCode: "LU", regExp: RegExp("^\\d{4}$") },
  { countryCode: "MK", regExp: RegExp("\\d{4}$") },
  { countryCode: "MY", regExp: RegExp("^\\d{5}$") },
  { countryCode: "MV", regExp: RegExp("^\\d{5}$") },
  { countryCode: "MT", regExp: RegExp("[A-Z]{3}[ ]?\\d{2,4}$") },
  { countryCode: "MU", regExp: RegExp("(\\d{3}[A-Z]{2}\\d{3})?$") },
  { countryCode: "MX", regExp: RegExp("^\\d{5}$") },
  { countryCode: "MD", regExp: RegExp("^\\d{4}$") },
  { countryCode: "MC", regExp: RegExp("980\\d{2}$") },
  { countryCode: "MA", regExp: RegExp("^\\d{5}$") },
  { countryCode: "NP", regExp: RegExp("^\\d{5}$") },
  { countryCode: "NZ", regExp: RegExp("^\\d{4}$") },
  {
    countryCode: "NI",
    regExp: RegExp("((\\d{4}-)?\\d{3}-\\d{3}(-\\d{1})?)?$"),
  },
  { countryCode: "NG", regExp: RegExp("(\\d{6})?$") },
  { countryCode: "OM", regExp: RegExp("(PC )?\\d{3}$") },
  { countryCode: "PK", regExp: RegExp("^\\d{5}$") },
  { countryCode: "PY", regExp: RegExp("^\\d{4}$") },
  { countryCode: "PH", regExp: RegExp("^\\d{4}$") },
  { countryCode: "PL", regExp: RegExp("\\d{2}-\\d{3}$") },
  { countryCode: "PR", regExp: RegExp("00[679]\\d{2}([ -]\\d{4})?$") },
  { countryCode: "RO", regExp: RegExp("^\\d{6}$") },
  { countryCode: "RU", regExp: RegExp("^\\d{6}$") },
  { countryCode: "SM", regExp: RegExp("^\\4789d$") },
  { countryCode: "SA", regExp: RegExp("^\\d{5}$") },
  { countryCode: "SN", regExp: RegExp("^\\d{5}$") },
  { countryCode: "SK", regExp: RegExp("\\d{3}[ ]?\\d{2}$") },
  { countryCode: "SI", regExp: RegExp("^\\d{4}$") },
  { countryCode: "ZA", regExp: RegExp("^\\d{4}$") },
  { countryCode: "LK", regExp: RegExp("^\\d{5}$") },
  { countryCode: "TJ", regExp: RegExp("^\\d{6}$") },
  { countryCode: "TH", regExp: RegExp("^\\d{5}$") },
  { countryCode: "TN", regExp: RegExp("^\\d{4}$") },
  { countryCode: "TR", regExp: RegExp("^\\d{5}$") },
  { countryCode: "TM", regExp: RegExp("^\\d{6}$") },
  { countryCode: "UA", regExp: RegExp("^\\d{5}$") },
  { countryCode: "UY", regExp: RegExp("^\\d{5}$") },
  { countryCode: "UZ", regExp: RegExp("^\\d{6}$") },
  { countryCode: "VA", regExp: RegExp("00120$") },
  { countryCode: "VE", regExp: RegExp("^\\d{4}$") },
  { countryCode: "ZM", regExp: RegExp("^\\d{5}$") },
  { countryCode: "AS", regExp: RegExp("96799$") },
  { countryCode: "CC", regExp: RegExp("6799$") },
  { countryCode: "CK", regExp: RegExp("^\\d{4}$") },
  { countryCode: "RS", regExp: RegExp("^\\d{6}$") },
  { countryCode: "ME", regExp: RegExp("8\\d{4}$") },
  { countryCode: "CS", regExp: RegExp("^\\d{5}$") },
  { countryCode: "YU", regExp: RegExp("^\\d{5}$") },
  { countryCode: "CX", regExp: RegExp("6798$") },
  { countryCode: "ET", regExp: RegExp("^\\d{4}$") },
  { countryCode: "FK", regExp: RegExp("FIQQ 1ZZ$") },
  { countryCode: "NF", regExp: RegExp("2899$") },
  { countryCode: "FM", regExp: RegExp("(9694[1-4])([ -]\\d{4})?$") },
  { countryCode: "GF", regExp: RegExp("9[78]3\\d{2}$") },
  { countryCode: "GN", regExp: RegExp("^\\d{3}$") },
  { countryCode: "GP", regExp: RegExp("9[78][01]\\d{2}$") },
  { countryCode: "GS", regExp: RegExp("SIQQ 1ZZ$") },
  { countryCode: "GU", regExp: RegExp("969[123]\\d([ -]\\d{4})?$") },
  { countryCode: "GW", regExp: RegExp("^\\d{4}$") },
  { countryCode: "HM", regExp: RegExp("^\\d{4}$") },
  { countryCode: "IQ", regExp: RegExp("^\\d{5}$") },
  { countryCode: "KG", regExp: RegExp("^\\d{6}$") },
  { countryCode: "LR", regExp: RegExp("^\\d{4}$") },
  { countryCode: "LS", regExp: RegExp("^\\d{3}$") },
  { countryCode: "MG", regExp: RegExp("^\\d{3}$") },
  { countryCode: "MH", regExp: RegExp("969[67]\\d([ -]\\d{4})?$") },
  { countryCode: "MN", regExp: RegExp("^\\d{6}$") },
  { countryCode: "MP", regExp: RegExp("9695[012]([ -]\\d{4})?$") },
  { countryCode: "MQ", regExp: RegExp("9[78]2\\d{2}$") },
  { countryCode: "NC", regExp: RegExp("988\\d{2}$") },
  { countryCode: "NE", regExp: RegExp("^\\d{4}$") },
  {
    countryCode: "VI",
    regExp: RegExp("008(([0-4]\\d)|(5[01]))([ -]\\d{4})?$"),
  },
  { countryCode: "PF", regExp: RegExp("987\\d{2}$") },
  { countryCode: "PG", regExp: RegExp("^\\d{3}$") },
  { countryCode: "PM", regExp: RegExp("9[78]5\\d{2}$") },
  { countryCode: "PN", regExp: RegExp("PCRN 1ZZ$") },
  { countryCode: "PW", regExp: RegExp("96940$") },
  { countryCode: "RE", regExp: RegExp("9[78]4\\d{2}$") },
  { countryCode: "SH", regExp: RegExp("(ASCN|STHL) 1ZZ$") },
  { countryCode: "SJ", regExp: RegExp("^\\d{4}$") },
  { countryCode: "SO", regExp: RegExp("^\\d{5}$") },
  { countryCode: "SZ", regExp: RegExp("[HLMS]\\d{3}$") },
  { countryCode: "TC", regExp: RegExp("TKCA 1ZZ$") },
  { countryCode: "WF", regExp: RegExp("986\\d{2}$") },
  { countryCode: "XK", regExp: RegExp("^\\d{5}$") },
  { countryCode: "YT", regExp: RegExp("976\\d{2}$") },
  { countryCode: "AQ", regExp: RegExp("^\\d{4}$") },
];
