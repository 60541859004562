import React from "react";

import classnames from "classnames/bind";

import style from "./CircleIndicator.module.css";

const cx = classnames.bind(style);

interface CircleIndicatorProps {
  idx?: number;
  show?: boolean;
  completed?: boolean;
  errored?: boolean;
  fixed?: boolean;
}

const CircleIndicator = ({
  idx,
  show = true,
  completed,
  errored,
  fixed,
}: CircleIndicatorProps) => {
  const hasIdx = typeof idx === "number";
  return (
    <div className={cx("circle", { hasIdx, show, completed, errored, fixed })}>
      {idx}
    </div>
  );
};

export default CircleIndicator;
