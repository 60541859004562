import React, { RefObject } from "react";
import { Switch, Route, useLocation } from "react-router";

import RegistrationComplete from "modules/form/components/RegistrationComplete";
import DeviceRegistration from "modules/form/components/iot/DeviceRegistration";
import DeviceCancellation from "modules/form/components/iot/DeviceCancellation";
import NoProductCode from "modules/form/components/noProductCode/NoProductCode";
import FormRoutes from "modules/form/components/FormRoutes";
// import ExternalLinks from "modules/layout/components/ExternalLinks";

interface ContentProps {
  formRef: RefObject<HTMLDivElement>;
}

export default function Content({ formRef }: ContentProps) {
  const location = useLocation();
  return (
    <Switch location={location}>
      <Route path="/noproductcode">
        <NoProductCode formRef={formRef} />
      </Route>
      <Route path="/iot/cancellation">
        <DeviceCancellation />
      </Route>
      <Route path="/iot/activation">
        <DeviceRegistration />
      </Route>
      <Route path="/result">
        <RegistrationComplete />
        {/* <ExternalLinks /> */}
      </Route>
      <Route path="/" component={() => <FormRoutes formRef={formRef} />} />
    </Switch>
  );
}
