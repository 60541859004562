import "react-app-polyfill/ie11";
import "core-js";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "modules/main/components/App";
import { addElementFromHTML } from "modules/gaInjection";

// This is the react way of adding this can be used instead of injection if preferred
// import ReactGA from "react-ga4";
// ReactGA.initialize("GTM-KBJ2ZHV");
addElementFromHTML(
  '<script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="297f044b-fd08-4b81-8189-daf574a0bfd5" data-blockingmode="auto" type="text/javascript"></script>',
  "head"
);

addElementFromHTML(
  "<!-- Google Tag Manager --> <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KBJ2ZHV');</script><!-- End Google Tag Manager -->",
  "head"
);

addElementFromHTML(
  '<!-- Google Tag Manager (noscript) --> <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KBJ2ZHV"height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript><!-- End Google Tag Manager (noscript) -->',
  "body"
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
