import React, { useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames/bind";

import useOutsideClick from "modules/util/useOutsideClick";

import srcArrowDown from "assets/Arrow_down.svg";

import style from "./DropDown.module.css";
const cx = classnames.bind(style);

type ListItem = {
  label: string;
  value: any;
};

interface DropDownProps {
  label: string;
  list: ListItem[];
  update: (value: any) => void;
  defaultValue?: string;
}

interface State {
  search: string;
  open: boolean;
}
const initialState: State = { search: "", open: false };

const DropDown = ({ label, list, update, defaultValue }: DropDownProps) => {
  const [{ open, search }, setState] = useState<State>(initialState);
  const ref = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const resetState = () => setState(initialState);
  const checkSearch = () => {
    const found = list.find((item) => {
      return item.label.toLowerCase() === search.toLowerCase();
    });
    if (found) {
      update(found.value);
    }
    resetState();
  };

  useOutsideClick(ref, () => checkSearch());

  const filteredList = list?.filter((item) => {
    if (!search) return true;
    const a = item.label.toLowerCase();
    const b = search.toLowerCase();
    return a.startsWith(b);
  });

  return (
    <div ref={ref}>
      <div
        className={style.root}
        onClick={() => setState((state) => ({ ...state, open: !open }))}
      >
        <input
          className={style.search}
          onClick={(e) => {
            setState((state) => ({ ...state, open: true }));
            e.stopPropagation();
          }}
          placeholder={defaultValue ?? label}
          onChange={(e) => {
            const search = e.target.value;
            setState({ open: true, search });
          }}
          ref={searchRef}
          value={search}
          onKeyDown={({ key }) => {
            if (key === "Enter" || key === "Tab") {
              checkSearch();
            }
          }}
        />
        <img className={cx(style.icon, { open })} src={srcArrowDown} alt="" />
      </div>
      {open && (
        <div className={style.select}>
          <div className={style.list}>
            {filteredList?.map((item, _) => {
              const selected = item.label === defaultValue;
              return (
                <div
                  className={cx(style.item, { selected })}
                  onClick={() => {
                    update(item.value);
                    resetState();
                  }}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
