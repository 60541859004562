import React from "react";
import classnames from "classnames/bind";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { useForm } from "modules/form/components/FormContext";
import { validateNumber } from "../../data/validation";

import NumericInput from "modules/common/components/NumericInput";

import style from "./RoomSize.module.css";
import CircleIndicator from "modules/common/components/CircleIndicator";
const cx = classnames.bind(style);

const messages = defineMessages({
  volume: {
    id: "room-size.label.volume",
    defaultMessage: "volume in m3",
    description: "label: volume (in meters cubed).",
  },
});

const RoomSize = () => {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();

  const height = state?.drinkPro20?.height;
  const width = state?.drinkPro20?.width;
  const volume = state?.drinkPro20?.volume;

  const validHeight = validateNumber(height);
  const validWidth = validateNumber(width);
  const validVolume = validateNumber(volume);

  const heightErrored = validHeight ? (height ?? 0) < 1 : false;
  const widthErrored = validWidth ? (width ?? 0) < 1 : false;

  const calcInputsDisabled = validVolume;
  const volumeInputDisabled = validHeight || validWidth;

  return (
    <div className={style.root}>
      <NumericInput
        label={formatMessage(messages.volume)}
        update={(volume) => setInState("drinkPro20", "volume", volume)}
        disabled={volumeInputDisabled}
        defaultValue={volume}
      />
      <div className={style.divide}>
        <FormattedMessage
          id="divide"
          defaultMessage="or"
          description="connect different possibilties."
        />
      </div>
      <div className={style.cont}>
        <div className={cx(style.calcSeg, { disabled: calcInputsDisabled })}>
          <div className={style.placeholder}>
            <FormattedMessage
              id="room-size.label.area"
              defaultMessage="height x width"
              description="input label for calculating area of a room."
            />
          </div>
          <div className={style.inputGroup}>
            <input
              className={cx(style.input, {
                errored: heightErrored,
              })}
              onChange={(e) =>
                setInState("drinkPro20", "height", parseFloat(e.target.value))
              }
              type="number"
              pattern="[0-9]*"
              disabled={calcInputsDisabled}
            />
            <div className={style.x}>x</div>
            <input
              className={cx(style.input, {
                errored: widthErrored,
              })}
              onChange={(e) =>
                setInState("drinkPro20", "width", parseFloat(e.target.value))
              }
              type="number"
              pattern="[0-9]*"
              disabled={calcInputsDisabled}
            />
          </div>
        </div>
        <div className={style.circle}>
          <CircleIndicator
            show={validHeight && validWidth}
            completed={!heightErrored && !widthErrored}
            errored={heightErrored || widthErrored}
          />
        </div>
      </div>
    </div>
  );
};

export default RoomSize;
