import React, { Fragment } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useLocation } from "react-router";

import srcGeneric from "assets/products/category/Generic-main.png";
import srcGenericMobile from "assets/products/category/Mobile-main.png";
import srcNoCodeDE from "assets/products/category/no-code-header-de.png";
import srcNoCodeMobileDE from "assets/products/category/no-code-header-small-de.png";
import srcNoCodeEN from "assets/products/category/no-code-header-en.png";
import srcNoCodeMobileEN from "assets/products/category/no-code-header-small-en.png";

import { useForm } from "modules/form/components/FormContext";
import { isGerman, locale } from "modules/util/constants";
import { isDanish } from "modules/util/constants";
import getProductCategoryImage from "modules/util/getProductCategoryImage";

import Button from "modules/common/components/Button";
import cx from "classnames";

import style from "./Header.module.css";

const messages = defineMessages({
  button: {
    id: "start-product-registration.button",
    defaultMessage: "Register your BWT product now",
    description:
      "button: scrolls down to the enter product code section to begin flow.",
  },
  noProductCodeButton: {
    id: "no-product-code.button",
    defaultMessage: "This is how it works",
    description:
      "button: scrolls down to instructions on what to do if you have no product code",
  },
});

interface StartProductRegistrationProps {
  onClick: () => void;
}

const StartProductRegistration = ({
  onClick,
}: StartProductRegistrationProps) => {
  const { formatMessage } = useIntl();
  return (
    <div className={style.sprRoot}>
      <div className={style.title}>
      {isDanish ? (
        <div>
          <Fragment/>
        </div>  ) : (
            <FormattedMessage
            id="header.start-product-registration.title"
            defaultMessage="Build step by step your"
            description="header title for start of product registration flow."
          />
          )}
        <div>
          <FormattedMessage
            id="header.start-product-registration.title-ending"
            defaultMessage="Best Water Home"
            description="header title for start of product registration flow ending."
          />
        </div>
      </div>
      <div className={style.subTitle}>
        <FormattedMessage
          id="header.start-product-registration.sub-title"
          defaultMessage='By registering BWT products, you benefit from numerous advantages. BWT not only ensures the best water in your home: as a customer, you can expect the best service in your personal "Best Water Home".'
          description="header sub title text for start of product registration flow"
        />
      </div>
      <Button
        name={formatMessage(messages.button)}
        btnStyle="front"
        size="large"
        onClick={onClick}
      />
    </div>
  );
};

interface NoProductCodeHeaderProps {
  onClick: () => void;
}

const NoProductCodeHeader = ({ onClick }: NoProductCodeHeaderProps) => {
  const { formatMessage } = useIntl();
  return (
    <div className={cx(style.sprRoot)}>
      <div className={style.title}>
        <FormattedMessage
          id="header.no-product-code.title"
          defaultMessage="Registration without product code"
          description="Header title for no product code page"
        />
      </div>
      <div className={style.body}>
        <FormattedMessage
          id="header.no-product-code.body"
          defaultMessage="Your product does not have an 8-digit product code? Here you can find out how to register BWT products without a code in just a few steps."
          description="Header sub title text for start of product registration flow"
        />
      </div>
      <Button
        name={formatMessage(messages.noProductCodeButton)}
        btnStyle="front"
        size="large"
        onClick={onClick}
      />
    </div>
  );
};

interface HeaderProps {
  scrollToFormRef: () => void;
}

const hiddenHeaderRoutes = ["/result", "/iot/activation", "/iot/cancellation"];

const Header = ({ scrollToFormRef }: HeaderProps) => {
  const { state } = useForm();
  const { pathname } = useLocation();

  const product = state?.lastProductResponse?.data;
  const productCategoryImage = getProductCategoryImage(
    product?.specificProductType
  );

  const [mainHeader, setMainHeader] = React.useState(
    productCategoryImage === ""
      ? window.innerWidth > 767
        ? srcGeneric
        : srcGenericMobile
      : ""
  );

  const srcNoCode = isGerman ? srcNoCodeDE : srcNoCodeEN;
  const srcNoCodeMobile = isGerman ? srcNoCodeMobileDE : srcNoCodeMobileEN;

  const [noCodeHeader, setNoCodeHeader] = React.useState(
    window.innerWidth > 767 ? srcNoCode : srcNoCodeMobile
  );

  window.addEventListener("resize", () => {
    if (window.innerWidth > 767) {
      setNoCodeHeader(srcNoCode);
      if (productCategoryImage === "") {
        setMainHeader(srcGeneric);
      }
    } else {
      setNoCodeHeader(srcNoCodeMobile);
      if (productCategoryImage === "") {
        setMainHeader(srcGenericMobile);
      }
    }
  });

  const hide = hiddenHeaderRoutes.some((route) => pathname.includes(route));
  if (hide) return <Fragment />;

  const alternateHeader = pathname.includes("/noproductcode");

  return (
    <div className={style.root}>
      {alternateHeader ? (
        <Fragment>
          <div
            style={{ backgroundImage: `url(${noCodeHeader})` }} //shows header than goes smaller to show mobile header
            className={style.content}
          />
          <NoProductCodeHeader onClick={() => scrollToFormRef()} />
        </Fragment>
      ) : (
        <Fragment>
          <div
            className={cx(style.content, {
              productImg: productCategoryImage !== "",
            })}
            style={{
              backgroundImage:
                productCategoryImage === ""
                  ? `url(${mainHeader})`
                  : `url(${productCategoryImage})`, //shows the main header
            }}
          ></div>
          <div className={style.imgAndText}>
            <StartProductRegistration onClick={() => scrollToFormRef()} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Header;
