import React, { useEffect } from "react";
import { useIntl, defineMessages } from "react-intl";

import { useForm } from "./FormContext";

import CategoryTitle from "modules/common/components/CategoryTitle";

import TextInput from "modules/common/components/TextInput";

import style from "./DisplayName.module.css";

const messages = defineMessages({
  name: {
    id: "form.label.display-name",
    defaultMessage: "Display name",
    description: "input label: enter a nickname for the selected product.",
  },
});

export default function DisplayName() {
  const { state, setInState } = useForm();
  const { formatMessage } = useIntl();

  const productData = state.lastProductResponse?.data;
  const deviceData = state.lastDeviceResponse?.data;

  useEffect(() => {
    if (productData) {
      setInState("form", "displayName", productData.name);
    }
    if (deviceData) {
      setInState("form", "displayName", deviceData.productName);
    }
  }, [deviceData, productData, setInState]);

  return (
    <div className={style.root}>
      <CategoryTitle title={formatMessage(messages.name)} />
      <TextInput
        label={formatMessage(messages.name)}
        update={(name: string) => setInState("form", "displayName", name)}
        defaultValue={state.form.displayName}
      />
    </div>
  );
}
