declare global {
  interface Window {
    LOCALE_PREFIX: string;
  }
}

export const sitecoreLocale = window.LOCALE_PREFIX
  ? window.LOCALE_PREFIX.replace("/", "")
  : window.location.pathname.split("/")[1];
export const locale = sitecoreLocale || navigator.language;
export const isGerman = locale.includes("de");
export const isEnglish = locale.includes("en");
export const isDanish = locale.includes("da");
