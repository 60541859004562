import { Co2Bottle, FilterCartridge } from "./types";

export default {
  cartridges: [
    {
      filterCartridge: FilterCartridge.M200,
      supportsIC50: true,
      articleNumber: "M200",
      displayName: "M200",
      shopLinks: [
        {
          countryCode: "AT",
          link:
            "https://www.bwt.com/de-at/produkte/wasserspender/filter-aqa-drink-mmw/m200/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link: "https://www.bwt.com/de-de/produkte/wasserspender/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1d",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1e",
          price: null,
          languageCode: "fr",
        },
      ],
    },
    {
      filterCartridge: FilterCartridge.M300,
      supportsIC50: true,
      articleNumber: "M300",
      displayName: "M300",
      shopLinks: [
        {
          countryCode: "AT",
          link: "https://www.bwt.com/de-at/produkte/wasserspender/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link:
            "https://www.bwt.com/de-de/produkte/wasserspender/filter-aqa-drink-mmw/m300/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1b",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1c",
          price: null,
          languageCode: "fr",
        },
      ],
    },
    {
      filterCartridge: FilterCartridge.M400,
      supportsIC50: true,
      articleNumber: "M400",
      displayName: "M400",
      shopLinks: [
        {
          countryCode: "AT",
          link:
            "https://www.bwt.com/de-at/produkte/wasserspender/filter-aqa-drink-mmw/m400/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link:
            "https://www.bwt.com/de-de/produkte/wasserspender/filter-aqa-drink-mmw/m400/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/19",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1a",
          price: null,
          languageCode: "fr",
        },
      ],
    },
    {
      filterCartridge: FilterCartridge.Mp200,
      supportsIC50: true,
      articleNumber: "MP200",
      displayName: "MP200",
      shopLinks: [
        {
          countryCode: "AT",
          link:
            "https://www.bwt.com/de-at/produkte/wasserspender/filter-aqa-drink-mp/mp200/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link:
            "https://www.bwt.com/de-de/produkte/wasserspender/filter-aqa-drink-mp/mp200/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/17",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/18",
          price: null,
          languageCode: "fr",
        },
      ],
    },
    {
      filterCartridge: FilterCartridge.Mp300,
      supportsIC50: true,
      articleNumber: "MP300",
      displayName: "MP300",
      shopLinks: [
        {
          countryCode: "AT",
          link: "https://www.bwt.com/de-at/produkte/wasserspender/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link: "https://www.bwt.com/de-de/produkte/wasserspender/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/15",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/16",
          price: null,
          languageCode: "fr",
        },
      ],
    },
    {
      filterCartridge: FilterCartridge.Mp400,
      supportsIC50: true,
      articleNumber: "MP400",
      displayName: "MP400",
      shopLinks: [
        {
          countryCode: "AT",
          link:
            "https://www.bwt.com/de-at/produkte/wasserspender/filter-aqa-drink-mp/mp400/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link:
            "https://www.bwt.com/de-de/produkte/wasserspender/filter-aqa-drink-mp/mp400/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/13",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/14",
          price: null,
          languageCode: "fr",
        },
      ],
    },
    {
      filterCartridge: FilterCartridge.Mpc400,
      supportsIC50: false,
      articleNumber: "MPC400",
      displayName: "MPC400",
      shopLinks: [
        {
          countryCode: "AT",
          link: "https://www.bwt.com/de-at/produkte/wasserspender/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link: "https://www.bwt.com/de-de/produkte/wasserspender/",
          price: null,
          languageCode: null,
        },
      ],
    },
    {
      filterCartridge: FilterCartridge.Mpc500,
      supportsIC50: false,
      articleNumber: "MPC500",
      displayName: "MPC500",
      shopLinks: [
        {
          countryCode: "AT",
          link:
            "https://www.bwt.com/de-at/produkte/wasserspender/filter-aqa-drink-mpc/mpc500/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link:
            "https://www.bwt.com/de-de/produkte/wasserspender/filter-aqa-drink-mpc/mpc500/",
          price: null,
          languageCode: null,
        },
      ],
    },
    {
      filterCartridge: FilterCartridge.Tc200,
      supportsIC50: false,
      articleNumber: "TC200",
      displayName: "TC200",
      shopLinks: [
        {
          countryCode: "AT",
          link: "https://www.bwt.com/de-de/produkte/wasserspender/tc200/",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link: "https://www.bwt.com/de-at/produkte/wasserspender/tc200/",
          price: null,
          languageCode: null,
        },
      ],
    },
  ],
  co2Bottles: [
    {
      co2Bottle: Co2Bottle.Bottle425g,
      requiredRoomVolume: 7,
      articleNumber: "co425",
      displayName: "425 g",
      shopLinks: [
        {
          countryCode: "AT",
          link:
            "https://www.bwt.com/de-at/produkte-fuer-zuhause/kohlensaeure-co2-bestellen",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link:
            "https://www.bwt.com/de-de/produkte-fuer-zuhause/kohlensaeure-co2-bestellen",
          price: null,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1f",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1g",
          price: null,
          languageCode: "fr",
        },
      ],
    },
    {
      co2Bottle: Co2Bottle.Bottle2Kg,
      requiredRoomVolume: 35,
      articleNumber: "co2",
      displayName: "2 kg",
      shopLinks: [
        {
          countryCode: "AT",
          link: null,
          price: 26.76,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link: null,
          price: 26.76,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1f",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1g",
          price: null,
          languageCode: "fr",
        },
      ],
    },
    {
      co2Bottle: Co2Bottle.Bottle3Kg,
      requiredRoomVolume: 51,
      articleNumber: "co3",
      displayName: "3 kg",
      shopLinks: [
        {
          countryCode: "AT",
          link: null,
          price: 31.56,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link: null,
          price: 31.56,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1f",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1g",
          price: null,
          languageCode: "fr",
        },
      ],
    },
    {
      co2Bottle: Co2Bottle.Bottle5Kg,
      requiredRoomVolume: 85,
      articleNumber: "co5",
      displayName: "5 kg",
      shopLinks: [
        {
          countryCode: "AT",
          link: null,
          price: 36.336,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link: null,
          price: 36.336,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1f",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1g",
          price: null,
          languageCode: "fr",
        },
      ],
    },
    {
      co2Bottle: Co2Bottle.Bottle6Kg,
      requiredRoomVolume: 105,
      articleNumber: "co6",
      displayName: "6 kg",
      shopLinks: [
        {
          countryCode: "AT",
          link: null,
          price: 40.2,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link: null,
          price: 40.2,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1f",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1g",
          price: null,
          languageCode: "fr",
        },
      ],
    },
    {
      co2Bottle: Co2Bottle.Bottle10Kg,
      requiredRoomVolume: 170,
      articleNumber: "co10",
      displayName: "10 kg",
      shopLinks: [
        {
          countryCode: "AT",
          link: null,
          price: 49.884,
          languageCode: null,
        },
        {
          countryCode: "DE",
          link: null,
          price: 49.884,
          languageCode: null,
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1f",
          price: null,
          languageCode: "de",
        },
        {
          countryCode: "CH",
          link: "https://l.softpauer.com/1g",
          price: null,
          languageCode: "fr",
        },
      ],
    },
  ],
};
