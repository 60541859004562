import React from "react";
import classnames from "classnames/bind";
import { defineMessages, useIntl } from "react-intl";
import { useLocation, useHistory } from "react-router";

import { useForm } from "./FormContext";
import { useAuth } from "modules/auth/components/AuthProvider";
import { validateProductDetails } from "../data/validation";

import CircleIndicator from "modules/common/components/CircleIndicator";

import style from "./ProgressBar.module.css";
const cx = classnames.bind(style);

interface ProgressItemProps {
  idx: number;
  label: string;
  completed: boolean;
  onClick?: () => void;
}

const ProgressItem = ({
  idx,
  label,
  completed,
  onClick,
}: ProgressItemProps) => {
  return (
    <div className={style.item} onClick={onClick}>
      <CircleIndicator
        idx={completed ? undefined : idx}
        completed={completed}
      />
      <div className={cx("label", { completed })}>{label}</div>
    </div>
  );
};

const messages = defineMessages({
  productCode: {
    id: "progress-bar.label.enter-product-code",
    defaultMessage: "Enter product code",
    description: "progress bar: enter product code stage.",
  },
  login: {
    id: "progress-bar.label.login-or-register",
    defaultMessage: "Log in or register",
    description: "progress bar: login or register stage.",
  },
  details: {
    id: "progress-bar.label.product-details",
    defaultMessage: "Product Details",
    description: "progress bar: product details stage.",
  },
});

export default function ProgressBar() {
  const { formatMessage } = useIntl();
  const { state, resetState } = useForm();
  const {
    state: { isLoggedIn },
  } = useAuth();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const lastProductResponseData = state?.lastProductResponse?.data;
  const alignStart = pathname === "/";
  const alignEnd = pathname === "/details";
  const alignCenter = !alignStart && !alignEnd;

  return (
    <div className={cx(style.root, { alignStart, alignEnd, alignCenter })}>
      <ProgressItem
        idx={1}
        label={formatMessage(messages.productCode)}
        completed={!!lastProductResponseData}
        onClick={() => {
          if (pathname !== "/") {
            push("/");
            resetState();
          }
        }}
      />
      <ProgressItem
        idx={2}
        label={formatMessage(messages.login)}
        completed={isLoggedIn}
      />
      <ProgressItem
        idx={3}
        label={formatMessage(messages.details)}
        completed={validateProductDetails(state)}
      />
    </div>
  );
}
