import React from "react";

import LoadingSpinner from "modules/common/components/LoadingSpinner";

import style from "./PageIsLoading.module.css";

export default function PageIsLoading() {
  return (
    <div className={style.root}>
      <LoadingSpinner />
    </div>
  );
}
