import React, { createContext, useReducer, Dispatch, useMemo } from "react";
import { formReducer, FormActions, Types, Category } from "../reducers";

import {
  Co2Bottle,
  FilterCartridge,
  PoolMaterial,
  PoolShape,
  PoolLocation,
  PoolDisinfectionType,
  Product,
  Device,
} from "../data/types";

export type FormState = {
  productCode: string;
  installationDate: Date;
  shareDataWithPartner: boolean;
  street: string;
  postCode: string;
  city: string;
  country: string;
  peoplePerHousehold?: number;
  agreeEmailContact?: boolean;
  contractTerms?: boolean;
  poolId?: number;
  displayName: string;
  acceptedContractDate?: Date | null;
};

export type PoolState = {
  name: string;
  volume?: number;
  length?: number;
  width?: number;
  depth?: number;
  location: PoolLocation;
  disinfection: PoolDisinfectionType;
  shape: PoolShape;
  material: PoolMaterial;
};

export type DrinkPro20State = {
  dailyWaterUsage: number;
  dailySodaUsage: number;
  volume?: number;
  height?: number;
  width?: number;
  currentCartridge: FilterCartridge;
  installedCO2Bottle: Co2Bottle;
  hasIC50: boolean;
};

export type IoTState = {
  agreeDataProcessing: boolean;
  agreeStatusUpdates: boolean;
};

export type FormContextState = {
  form: FormState;
  pool?: PoolState;
  drinkPro20?: DrinkPro20State;
  iot?: IoTState;
  lastProductResponse?: { data: Product };
  lastDeviceResponse?: { data: Device };
};

export const initialState = {
  form: {
    productCode: "",
    installationDate: new Date(),
    shareDataWithPartner: false,
    street: "",
    postCode: "",
    city: "",
    country: "AT",
    displayName: "",
    acceptedContractDate: null,
    contractTerms: false,
  },
};

const FormContext = createContext<{
  state: FormContextState;
  dispatch: Dispatch<FormActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const FormProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};

const buildSetter = (dispatch: Dispatch<FormActions>) => (
  category: string,
  key: string,
  value: string | number | Date | boolean | Product | Device | undefined
) => {
  dispatch({
    type: Types.UpdateForm,
    payload: {
      category: Category[category as keyof typeof Category],
      key,
      value,
    },
  });
};

const buildResetter = (dispatch: Dispatch<FormActions>) => () => {
  dispatch({
    type: Types.ResetForm,
    payload: null,
  });
};

export function useForm() {
  const { state, dispatch } = React.useContext(FormContext);
  const setInState = useMemo(() => buildSetter(dispatch), [dispatch]);
  const resetState = useMemo(() => buildResetter(dispatch), [dispatch]);

  return { state, setInState, resetState };
}

export default FormProvider;
