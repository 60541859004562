import React, { useState, useEffect, useCallback, Fragment } from "react";
import { defineMessages, FormattedDate, useIntl } from "react-intl";
import { useHistory } from "react-router";

import { useForm } from "./FormContext";
import { validateProductDetails } from "../data/validation";
import {
  registerPool,
  registerPoolProduct,
  registerProduct,
  registerAqaDrinkProduct,
} from "modules/api/endpoints";
import { ProductType } from "../data/types";
import { useAuth } from "modules/auth/components/AuthProvider";
import { isDanish } from "modules/util/constants";

import Button from "modules/common/components/Button";

import PeopleInTheHouse from "./PeopleInTheHouse";
import AgreeEmailContact from "./AgreeEmailContact";
import ServiceCheckbox from "./ServiceCheckbox";
import PoolForm from "./pool/PoolForm";
import DrinkPro20Form from "./drinkPro20/DrinkPro20Form";
import ProductLocation from "./ProductLocation";
import Error from "modules/common/components/Error";
import DisplayName from "./DisplayName";
import PopUpContent from "modules/common/components/popUp/PopUpContent";
import ModalContent from "./ModalContent";

import style from "./ProductDetails.module.css";

const messages = defineMessages({
  button: {
    id: "product-details.button.register-product",
    defaultMessage: "Register Product",
    description: "button text: completes the product registration.",
  },
});

async function getCachedFormData() {
  await null;
  return localStorage.getItem("cached-form-data");
}

export default function ProductDetails() {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();
  const { push } = useHistory();
  const {
    state: { isLoggedIn },
    setAuth,
  } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) push("/redirect");
  }, [isLoggedIn]);

  const [{ isBusy, showError, lastErrorCode }, setState] = useState({
    isBusy: false,
    showError: false,
    lastErrorCode: null,
  });

  const [showOverlay, setShowOverlay] = useState(false);

  const { imageUrl, productType } = state?.lastProductResponse?.data || {};
  const valid = validateProductDetails(state);

  const sitecoreLocale = window.LOCALE_PREFIX
    ? window.LOCALE_PREFIX.replace("/", "")
    : window.location.pathname.split("/")[1];
  const locale = sitecoreLocale || navigator.language;

  useEffect(() => {
    const runEffect = async () => {
      const storageItem = await getCachedFormData();
      if (!storageItem) {
        if (!state.form.productCode) push("/");
        return;
      }

      const cachedFormData = JSON.parse(storageItem);
      if (!cachedFormData || !cachedFormData.productCode) {
        if (!state.form.productCode) push("/");
        return;
      }

      setInState("form", "productCode", cachedFormData.productCode);
      setInState(
        "form",
        "installationDate",
        new Date(cachedFormData.installationDate)
      );
      setInState(
        "form",
        "shareDataWithPartner",
        cachedFormData.shareDataWithPartner
      );
      setInState(
        "lastProductResponse",
        "data",
        cachedFormData.lastProductResponseData
      );
    };
    runEffect();
  }, []);

  const checkContractTermsToggled = () => {
    if (!state.form.contractTerms && isDanish) {
      setShowOverlay(true);
    } else {
      sendRequest();
    }
  };

  const sendRequest = useCallback(
    async (generateDate: boolean = false) => {
      if (generateDate) {
        state.form.contractTerms = true;
        state.form.acceptedContractDate = new Date();
      }
      if (isBusy) return;
      setState({ isBusy: true, showError: false, lastErrorCode: null });
      if (productType === ProductType.Pool) {
        let poolId = state.form.poolId;
        if (!poolId && state.pool) {
          const length = state.pool.length ?? 0;
          const width = state.pool.width ?? 0;
          const depth = state.pool.depth ?? 0;
          const volume = state.pool.volume ?? length * width * depth;

          const { success, data, errorCode, unauthorized } = await registerPool(
            {
              name: state.pool.name,
              volume,
              disinfectionType: state.pool.disinfection,
              location: state.pool.location,
              shape: state.pool.shape,
              material: state.pool.material,
              countryCode: state.form.country,
              street: state.form.street,
              city: state.form.city,
              postalCode: state.form.postCode,
              acceptedContractTerms: state.form.contractTerms,
              acceptedContractDate: state.form.contractTerms
                ? new Date().toISOString()
                : undefined,
            }
          );
          if (success) {
            if (data) {
              poolId = data.id;
            }
          } else {
            setState((state) => ({
              ...state,
              showError: true,
              lastErrorCode: errorCode,
            }));
            if (unauthorized) {
              setAuth({
                isBusy: false,
                isLoggedIn: false,
                profile: undefined,
              });
              push("/redirect");
            }
          }
        }
        if (!poolId) return;
        const { success, errorCode, unauthorized } = await registerPoolProduct({
          poolId: poolId,
          productCode: state.form.productCode,
          installationDate: state.form.installationDate.toISOString(),
          isDataShared: state.form.shareDataWithPartner,
        });
        if (success) {
          push("/result");
        } else {
          setState((state) => ({
            ...state,
            showError: true,
            lastErrorCode: errorCode,
          }));
          if (unauthorized) {
            setAuth({
              isBusy: false,
              isLoggedIn: false,
              profile: undefined,
            });
            push("/redirect");
          }
        }
      } else if (productType === ProductType.WaterDispenser) {
        if (!state.drinkPro20) return;
        const height = state.drinkPro20.height ?? 0;
        const width = state.drinkPro20.width ?? 0;
        const area = height * width;
        const size = state.drinkPro20.volume ? state.drinkPro20.volume : area;
        const { success, errorCode, unauthorized } =
          await registerAqaDrinkProduct({
            displayName: state.form.displayName,
            filterCartridge: state.drinkPro20?.currentCartridge,
            co2Bottle: state.drinkPro20.installedCO2Bottle,
            roomVolume: size,
            hasIc50: state.drinkPro20.hasIC50,
            dailySodaConsumption: state.drinkPro20.dailySodaUsage,
            dailyWaterConsumption: state.drinkPro20.dailyWaterUsage,
            productCode: state.form.productCode,
            installationDate: state.form.installationDate.toISOString(),
            street: state.form.street,
            city: state.form.city,
            postalCode: state.form.postCode,
            countryCode: state.form.country,
            shareDataWithPartner: state.form.shareDataWithPartner,
            personCount: state.form.peoplePerHousehold ?? 0,
            acceptedContractTerms: state.form.contractTerms,
            acceptedContractDate: state.form.contractTerms
              ? new Date().toISOString()
              : undefined,
          });
        if (success) {
          push("/result");
        } else {
          setState((state) => ({
            ...state,
            showError: true,
            lastErrorCode: errorCode,
          }));
          if (unauthorized) {
            setAuth({
              isBusy: false,
              isLoggedIn: false,
              profile: undefined,
            });
            push("/redirect");
          }
        }
      } else if (productType === ProductType.Default) {
        const { success, errorCode, unauthorized } = await registerProduct({
          productCode: state.form.productCode,
          installationDate: state.form.installationDate.toISOString(),
          street: state.form.street,
          city: state.form.city,
          postalCode: state.form.postCode,
          countryCode: state.form.country,
          shareDataWithPartner: state.form.shareDataWithPartner,
          personCount: state.form.peoplePerHousehold ?? 0,
          displayName: state.form.displayName,
          acceptedContractTerms: state.form.contractTerms,
          acceptedContractDate: state.form.contractTerms
            ? new Date().toISOString()
            : undefined,
        });
        if (success) {
          push("/result");
        } else {
          setState((state) => ({
            ...state,
            showError: true,
            lastErrorCode: errorCode,
          }));
          if (unauthorized) {
            setAuth({
              isBusy: false,
              isLoggedIn: false,
              profile: undefined,
            });
            push("/redirect");
          }
        }
      }

      setState((state) => ({ ...state, isBusy: false }));
    },
    [productType, push, state, isBusy]
  );

  return (
    <div>
      <Error show={showError} errorCode={lastErrorCode} />
      <div className={style.root}>
        {isDanish ? (
          <PopUpContent
            isShowing={showOverlay}
            hide={() => setShowOverlay(false)}
            showClose={true}
          >
            <ModalContent
              buttonNo={[
                {
                  title: "Ellers tak!",
                  click: () => {
                    setShowOverlay(false);
                    sendRequest();
                  },
                },
              ]}
              buttonYes={[
                {
                  title: "Jo tak! Jeg ønsker en Tryghed Premium aftale.",
                  click: () => {
                    setShowOverlay(false);
                    sendRequest(true);
                  },
                },
              ]}
            />
          </PopUpContent>
        ) : (
          <Fragment />
        )}
        <div className={style.segTop}>
          <div
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
            className={style.img}
          />
          <div>
            {productType !== ProductType.Pool && <DisplayName />}
            <ProductLocation />
          </div>
        </div>
        <div className={style.segBottom}>
          {productType === ProductType.Pool ? (
            <PoolForm />
          ) : productType === ProductType.WaterDispenser ? (
            <DrinkPro20Form />
          ) : (
            productType === ProductType.Default && <PeopleInTheHouse />
          )}
          {productType !== ProductType.Default && <AgreeEmailContact />}
          {isDanish ? (
            productType === ProductType.Default && <ServiceCheckbox />
          ) : (
            <Fragment />
          )}
          <div className={style.btn}>
            <Button
              name={formatMessage(messages.button)}
              onClick={checkContractTermsToggled}
              btnStyle="primary"
              disabled={!valid || isBusy}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
