import React, { Fragment, RefObject } from "react";
import { FormattedMessage } from "react-intl";

import ProgressBar from "./ProgressBar";
// import ExternalLinks from "modules/layout/components/ExternalLinks";
import NoProductCodeHelper from "./NoProductCodeHelper";
import PlumberOrProfessional from "./PlumberOrProfessional";

import style from "./FormContainer.module.css";

const FormContainer: React.FC<{ formRef: RefObject<HTMLDivElement> }> = ({
  children,
  formRef,
}) => {
  const sitecoreLocale = window.LOCALE_PREFIX
    ? window.LOCALE_PREFIX.replace("/", "")
    : window.location.pathname.split("/")[1];
  const locale = sitecoreLocale || navigator.language;

  return (
    <div ref={formRef} className={style.root}>
      <div className={style.content}>
        <div className={style.title}>
          <FormattedMessage
            id="form-container.title"
            defaultMessage="Register BWT product and receive Best Water Home benefits"
            description="sub title for register product form section."
          />
        </div>
        <ProgressBar />
      </div>
      <div className={style.seg}>{children}</div>
      <NoProductCodeHelper />
      <PlumberOrProfessional />
      {/* <ExternalLinks /> */}
    </div>
  );
};

export default FormContainer;
