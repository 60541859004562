import React from "react";
import { useIntl, FormattedDate } from "react-intl";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import isAfter from "date-fns/isAfter";
import isEqual from "date-fns/isEqual";
import startOfDay from "date-fns/startOfDay";
import { de, fr, it, nl, hu, pl, ru, fi, es } from "date-fns/locale";

import "react-datepicker/dist/react-datepicker.css";
import style from "./DatePicker.module.css";

registerLocale("de", de);
registerLocale("fr", fr);
registerLocale("it", it);
registerLocale("nl", nl);
registerLocale("hu", hu);
registerLocale("pl", pl);
registerLocale("ru", ru);
registerLocale("fi", fi);
registerLocale("es", es);

interface DatePickerProps {
  label: string;
  defaultValue: Date;
  updateParent: (date: Date) => void;
  blockFutureDates?: boolean;
}

const DatePicker = ({
  label,
  defaultValue,
  updateParent,
  blockFutureDates = false,
}: DatePickerProps) => {
  const now = new Date();
  const max = blockFutureDates ? now : undefined;
  const { locale } = useIntl();
  const language = locale.split("-")[0];
  return (
    <div style={{ position: "relative" }}>
      <ReactDatePicker
        selected={defaultValue}
        onChange={(date) => {
          if (date) updateParent(date);
        }}
        maxDate={max}
        className={style.root}
        dayClassName={(date) => {
          const now = new Date();
          const dateIsInFuture = isAfter(date, now);
          const dateIsSelected = isEqual(date, startOfDay(defaultValue));
          if (dateIsInFuture) {
            return style.future;
          }
          if (dateIsSelected) {
            return style.selected;
          }
          return style.unselected;
        }}
        locale={language}
      />
      <div className={style.label}>{label}</div>
      <div className={style.date}>
        <FormattedDate
          value={defaultValue}
          year="numeric"
          month="2-digit"
          day="2-digit"
        />
      </div>
    </div>
  );
};

export default DatePicker;
