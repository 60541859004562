import React, { useRef, useEffect } from "react";
import { Router, useHistory, useLocation } from "react-router";
import { createBrowserHistory } from "history";

import ConnectedIntlProvider from "modules/intl/components/ConnectedIntlProvider";
import FormProvider from "modules/form/components/FormContext";
import Header from "modules/layout/components/Header";
import PopUpProvider from "modules/common/components/popUp/PopUpProvider";
import Page from "modules/layout/components/Page";
import Content from "./Content";
import AuthProvider from "modules/auth/components/AuthProvider";

import config from "config";

import style from "./App.module.css";

function App() {
  const formRef = useRef<HTMLDivElement>(null);

  const scrollToFormRef = () => {
    if (!formRef.current) return;
    const top = formRef.current?.offsetTop - 100; // sitecore header is 85px
    window.scrollTo({
      top,
      behavior: "smooth",
    });
  };

  function ScrollRestoration() {
    const { pathname } = useLocation();

    useEffect(() => {
      if (pathname === "/details") {
        scrollToFormRef();
      } else {
        window.scrollTo(0, 0);
      }
    }, [pathname]);

    return null;
  }

  const history = createBrowserHistory({
    basename: config.basename,
  });

  return (
    <AuthProvider>
      <ConnectedIntlProvider>
        <PopUpProvider>
          <Router history={history}>
            <ScrollRestoration />
            <FormProvider>
              <div className={style.root}>
                <Header scrollToFormRef={scrollToFormRef} />
                <Page>
                  <Content formRef={formRef} />
                </Page>
              </div>
            </FormProvider>
          </Router>
        </PopUpProvider>
      </ConnectedIntlProvider>
    </AuthProvider>
  );
}

export default App;
