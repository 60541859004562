import React from "react";

import getDeviceImage from "modules/util/getDeviceImage";

import style from "./DeviceImage.module.css";

interface DeviceImageProps {
  type: string;
  version: string;
}

export default function DeviceImage({ type, version }: DeviceImageProps) {
  const deviceImage = getDeviceImage(type, version);
  return (
    <div
      className={style.root}
      style={{
        backgroundImage: `url(${deviceImage})`,
      }}
    />
  );
}
