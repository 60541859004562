import React, { useState } from "react";
import classnames from "classnames/bind";

import style from "./NumericInput.module.css";
import CircleIndicator from "./CircleIndicator";

const cx = classnames.bind(style);

function validateNumber(ranges: { min: number; max?: number }, value?: number) {
  if (value === undefined) {
    return false;
  }
  if (!isFinite(value)) {
    return false;
  }
  if (ranges.min) {
    if (value < ranges.min) {
      return false;
    }
  }
  if (ranges.max) {
    if (value > ranges.max) {
      return false;
    }
  }
  return true;
}

interface NumericInputProps {
  label: string;
  defaultValue?: number;
  update: (value: number) => void;
  disabled?: boolean;
  ranges?: { min: number; max?: number };
}

const NumericInput = ({
  label,
  defaultValue,
  update,
  disabled,
  ranges = { min: 0, max: 10000 },
}: NumericInputProps) => {
  const [{ focused, hasChanged }, setState] = useState({
    focused: false,
    hasChanged: false,
  });
  const errored = !validateNumber(ranges, defaultValue);
  const showError = hasChanged && errored && !disabled;
  const showFocused = focused && !disabled;

  return (
    <div className={style.root}>
      <div
        className={cx(style.seg, {
          disabled,
          focused: showFocused,
          errored: showError,
        })}
      >
        <div className={style.placeholder}>{label}</div>
        <input
          className={style.input}
          onChange={(event) => {
            setState((state) => ({ ...state, hasChanged: true }));
            const valueAsFloat = parseFloat(event.target.value);
            update(valueAsFloat);
          }}
          value={defaultValue}
          type="number"
          pattern="[0-9]*"
          disabled={disabled}
          onFocus={() => setState((state) => ({ ...state, focused: true }))}
          onBlur={() => setState((state) => ({ ...state, focused: false }))}
        />
      </div>
      <div className={style.circle}>
        <CircleIndicator
          show={hasChanged && !disabled}
          completed={!errored}
          errored={errored}
        />
      </div>
    </div>
  );
};

export default NumericInput;
