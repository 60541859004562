//google
import srcGoogleEn from "assets/appStore/badges/google/en.png";
import srcGoogleDe from "assets/appStore/badges/google/de.png";
import srcGoogleFr from "assets/appStore/badges/google/fr.png";
import srcGoogleIt from "assets/appStore/badges/google/it.png";
import srcGoogleNl from "assets/appStore/badges/google/nl.png";
import srcGoogleDk from "assets/appStore/badges/google/dk.png";
import srcGoogleHu from "assets/appStore/badges/google/hu.png";
import srcGooglePl from "assets/appStore/badges/google/pl.png";
import srcGoogleUa from "assets/appStore/badges/google/ua.png";
import srcGoogleRu from "assets/appStore/badges/google/ru.png";
import srcGoogleSe from "assets/appStore/badges/google/se.png";
import srcGoogleNo from "assets/appStore/badges/google/no.png";
import srcGoogleFi from "assets/appStore/badges/google/fi.png";
import srcGoogleEs from "assets/appStore/badges/google/es.png";

//apple
import srcAppleEn from "assets/appStore/badges/apple/en.svg";
import srcAppleDe from "assets/appStore/badges/apple/de.svg";
import srcAppleFr from "assets/appStore/badges/apple/fr.svg";
import srcAppleIt from "assets/appStore/badges/apple/it.svg";
import srcAppleNl from "assets/appStore/badges/apple/nl.svg";
import srcAppleDk from "assets/appStore/badges/apple/dk.svg";
import srcAppleHu from "assets/appStore/badges/apple/hu.svg";
import srcApplePl from "assets/appStore/badges/apple/pl.svg";
import srcAppleRu from "assets/appStore/badges/apple/ru.svg";
import srcAppleSe from "assets/appStore/badges/apple/se.svg";
import srcAppleNo from "assets/appStore/badges/apple/no.svg";
import srcAppleFi from "assets/appStore/badges/apple/fi.svg";
import srcAppleEs from "assets/appStore/badges/apple/es.svg";

const assets: Record<string, Record<string, string>> = {
  google: {
    en: srcGoogleEn,
    de: srcGoogleDe,
    fr: srcGoogleFr,
    it: srcGoogleIt,
    nl: srcGoogleNl,
    dk: srcGoogleDk,
    hu: srcGoogleHu,
    pl: srcGooglePl,
    ua: srcGoogleUa,
    ru: srcGoogleRu,
    se: srcGoogleSe,
    no: srcGoogleNo,
    fi: srcGoogleFi,
    es: srcGoogleEs,
  },
  apple: {
    en: srcAppleEn,
    de: srcAppleDe,
    fr: srcAppleFr,
    it: srcAppleIt,
    nl: srcAppleNl,
    dk: srcAppleDk,
    hu: srcAppleHu,
    pl: srcApplePl,
    ua: srcAppleEn, // couldn't find asset, default to EN?
    ru: srcAppleRu,
    se: srcAppleSe,
    no: srcAppleNo,
    fi: srcAppleFi,
    es: srcAppleEs,
  },
};

export default function getLocalisedAppStoreImage(
  name: string,
  languageCode: string
): string {
  return assets[name][languageCode];
}
