import srcPerla from "assets/products/iot/perla.png";
import srcSilk from "assets/products/iot/Perla-Silk.png";
import srcCillitDuo from "assets/products/iot/Cillit-Duo.png";

export default function getDeviceImage(type: string, version: string) {
  if (version === "Duo") return srcCillitDuo;
  switch (type) {
    case "persil":
      return srcSilk;
    case "aqa perla":
    default:
      return srcPerla;
  }
}
