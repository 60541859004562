import cs from "./cs.country_translations.json";
import da from "./da.country_translations.json";
import de from "./de.country_translations.json";
import en from "./en.country_translations.json";
import es from "./es.country_translations.json";
import fi from "./fi.country_translations.json";
import fr from "./fr.country_translations.json";
import hu from "./hu.country_translations.json";
import it from "./it.country_translations.json";
import nl from "./nl.country_translations.json";
import no from "./no.country_translations.json";
import pl from "./pl.country_translations.json";

export default { cs, da, de, en, es, fi, fr, hu, it, nl, no, pl };
