import React from "react";
import { defineMessages, useIntl } from "react-intl";

import { useForm } from "./FormContext";

import CheckBox from "modules/common/components/CheckBox";

import style from "./ServiceCheckbox.module.css";

const messages = defineMessages({
    label: {
        id: "service.check-box.label",
        defaultMessage:
            "I agree to the two year service. I can cancel my participation at any time. T&C's apply",
        description: "label for accepting free two year service check box.",
    },
    termsButton: {
        id: "link-to-terms.button",
        defaultMessage: "Link to Terms",
        description: "button text: link to service terms.",
      }
});

const ServiceCheckbox = () => {
    const { state, setInState } = useForm();
    const { formatMessage } = useIntl();

    return (
        <div className={style.root}>
            <CheckBox
                label={formatMessage(messages.label)} 
                checked={state.form.contractTerms ?? false}
                onClick={(contractTerms) =>
                    setInState("form", "contractTerms", contractTerms)
                }
                
            />
            <a
            href="https://www.bwt.com/da-dk/service-til-privat/tryghedsaftaler/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatMessage(messages.termsButton)}
          </a>
        </div>
    );
};

export default ServiceCheckbox;
