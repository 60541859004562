import React, { createContext, useState, useMemo, useEffect } from "react";

import { checkUserIsLoggedIn, getUserProfile } from "modules/api/endpoints";
import { Profile } from "modules/form/data/types";

import PageIsLoading from "modules/layout/components/PageIsLoading";

export type AuthContextState = {
  isLoggedIn: boolean;
  profile?: Profile;
  isBusy: boolean;
};

export const initialState = {
  isLoggedIn: false,
  isBusy: true,
};

const AuthContext = createContext<{
  state: AuthContextState;
  setAuth: (state: AuthContextState) => void;
}>({
  state: initialState,
  setAuth: () => {},
});

const buildSetter = (
  setState: React.Dispatch<React.SetStateAction<AuthContextState>>
) => (state: AuthContextState) => setState(state);

const AuthProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);
  const setAuth = useMemo(() => buildSetter(setState), [setState]);

  useEffect(() => {
    const runEffect = async () => {
      const { success } = await checkUserIsLoggedIn();
      if (success) {
        setAuth({ isBusy: true, isLoggedIn: true, profile: undefined });
        const { data } = await getUserProfile();
        if (data) {
          setAuth({ isBusy: false, isLoggedIn: true, profile: data });
        } else {
          setAuth({ isBusy: false, isLoggedIn: true, profile: undefined });
        }
      } else {
        setAuth({ isBusy: false, isLoggedIn: false, profile: undefined });
      }
    };
    runEffect();
  }, [setAuth]);

  if (state.isBusy) return <PageIsLoading />;

  return (
    <AuthContext.Provider value={{ state, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const { state, setAuth } = React.useContext(AuthContext);
  return { state, setAuth };
}

export default AuthProvider;
