import React, { useEffect } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";

import { useForm } from "./FormContext";
import { useExternalLinkItems } from "modules/layout/data/externalLinks";
import getProductCategoryImage from "modules/util/getProductCategoryImage";

import BWTWorld from "./BWTWorld";
import CircleIndicator from "modules/common/components/CircleIndicator";
import Heading from "modules/common/components/Heading";
import { StoreLink } from "modules/layout/components/ExternalLinks";

import style from "./RegistrationComplete.module.css";
import getDeviceImage from "modules/util/getDeviceImage";
import Benefits from "./Benefits";
import { isEnglish } from "modules/util/constants";

const messages = defineMessages({
  title: {
    id: "registration-complete.title",
    defaultMessage: "Thank you for registering your BWT product.",
    description:
      "title: congratulate the user on successfully registering their product.",
  },
  ukMessageStart: {
    id: "registration-complete.optional-message.start",
    defaultMessage: "Your online shop voucher code is BWTOSV10. Please visit",
    description: "message only shown in the uk about shop discounts",
  },
  ukMessageEnd: {
    id: "registration-complete.optional-message.end",
    defaultMessage: "to save 10% on selected products. Valid for one use only.",
    description: "message only shown in the uk about shop discounts",
  },
});

const RegistationComplete = () => {
  const { state, resetState } = useForm();
  const { replace } = useHistory();
  const { formatMessage } = useIntl();

  // hide/unhide "content tiles" in sitecore.
  useEffect(() => {
    const elem = document.querySelector("[data-thankyou-content]");
    elem?.setAttribute("style", "display: block");
    return () => elem?.setAttribute("style", "display: none");
  }, []);

  const product = state?.lastProductResponse?.data;
  const device = state?.lastDeviceResponse?.data;

  const name = device ? device.productName : product ? product.name : "";
  const productCode = device
    ? device.productCode
    : product
    ? product.productCode
    : "";
  const image = device
    ? getDeviceImage(device.deviceType, device.deviceVersion)
    : getProductCategoryImage(product?.specificProductType);

  const externalLinkItems = useExternalLinkItems();
  const storeLinks = externalLinkItems.find((item) =>
    item.links.find((link) => link.type === "store")
  )?.links;

  return (
    <div className={style.root}>
      <Heading message={formatMessage(messages.title)} />
      {isEnglish && state.form.country === "GB" ? (
        <div className={style.smallHeader}>
          <span className={style.messageSection}>{formatMessage(messages.ukMessageStart)}</span>{" "}
          <span
            className={style.ukLink}
            onClick={() => window.open("https://bwtshop.co.uk")}
          >
            {"bwtshop.co.uk"}
          </span>{" "}
          <span className={style.messageSection}>{formatMessage(messages.ukMessageEnd)}</span>
        </div>
      ) : (
        <div />
      )}
      <div className={style.seg}>
        <div className={style.product}>
          <div className={style.info}>
            <div className={style.name}>{name}</div>
            <div className={style.code}>
              <span>{productCode}</span>
              <CircleIndicator completed={true} />
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${image})`,
            }}
            className={style.img}
          />
          <div
            className={style.link}
            onClick={() => {
              resetState();
              replace("/");
            }}
          >
            <FormattedMessage
              id="registration-complete.button"
              defaultMessage="Register more products"
              description="button: go back to start of flow to register a new product."
            />
          </div>
        </div>
        <Benefits />
      </div>
      <div className={style.text}>
        <FormattedMessage
          id="registration-complete.text"
          defaultMessage="Your product and additional service is ready for you in the Best Water Home App."
          description="explanation text of viewing product in at-home app."
        />
      </div>
      <div className={style.store}>
        {storeLinks?.map((link, idx) => (
          <div key={idx} className={style.appStore}>
            <StoreLink link={link} />
          </div>
        ))}
      </div>
      <BWTWorld />
    </div>
  );
};

export default RegistationComplete;
