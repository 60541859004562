import React, { useRef, useState } from "react";

import classnames from "classnames/bind";

import style from "./Button.module.css";

const cx = classnames.bind(style);

interface ButtonProps {
  name: string;
  onClick?: () => void;
  btnStyle: string;
  size?: string;
  disabled?: boolean;
  icons?: {
    default?: string;
    hover?: string;
  };
  colour?: string;
}

const Button: React.FC<ButtonProps> = ({
  name,
  onClick,
  btnStyle,
  disabled,
  size,
  icons,
  colour,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hovered, setHovered] = useState(false);
  const icon = hovered ? icons?.hover : icons?.default;
  return (
    <div
      ref={ref}
      className={cx("root", btnStyle, colour, size, { disabled, hovered })}
      onClick={disabled ? undefined : onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onTouchStart={() => setHovered(true)}
      onTouchEnd={() => setHovered(false)}
    >
      {icon && <img src={icon} className={style.icon} alt="" />}
      <span>{name}</span>
    </div>
  );
};

export default Button;
