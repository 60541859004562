/**
 *create element from html string for injecting into html
 */
export function createElementFromHTML(htmlString: string, element: string) {
  var div = document.createElement(element);
  div.innerHTML = htmlString.trim();
  return div;
}

/**
 * convert a sting of html to html elemnt and inject into html appended to the bottom of the segment
 * @param htmlString - string of html to be converted to html element
 * @param element - html element to be injected at the end of e.g. body, head, div
 */
export function addElementFromHTML(htmlString: string, element: string) {
  var div = createElementFromHTML(htmlString, element);
  var el = document.getElementsByTagName(element);
  if (el && el.length > 0 && div.firstChild && div.lastChild) {
    if (div.lastChild && div.lastChild !== div.firstChild) {
      el[0].insertBefore(div.lastChild, el[0].firstChild);
    }
    if (div.children[0]) {
      el[0].insertBefore(div.children[0], el[0].firstChild);
    }

    if (div.firstChild) {
      el[0].insertBefore(div.firstChild, el[0].firstChild);
    }
  }
  return el;
}
