import React, { Fragment, useRef, useEffect } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { MessageDescriptor } from "react-intl/lib/types";

import { ErrorCode } from "modules/form/data/types";

import srcError from "assets/warning.svg";

import style from "./Error.module.css";

const messages = defineMessages({
  registrationCodeNotFound: {
    id: "error.registration-code-not-found",
    defaultMessage: "Your device registration code could not be found.",
    description: "error message: something went wrong server-side.",
  },
  registrationCodeForIotDevice: {
    id: "error.registration-code-for-iot-device",
    defaultMessage: "The registration code belongs to an IoT device.",
    description: "error message: something went wrong server-side.",
  },
  registrationCodeAlreadyRegistered: {
    id: "error.registration-code-already-registered",
    defaultMessage: "The registration code is already registered.",
    description: "error message: something went wrong server-side.",
  },
  invalidPersonCount: {
    id: "error.invalid-person-count",
    defaultMessage: "The value entered in person count is invalid.",
    description: "error message: something went wrong server-side.",
  },
  invalidRegistrationDetails: {
    id: "error.invalid-registration-details",
    defaultMessage: "One or more of the details you have entered are invalid.",
    description: "error message: something went wrong server-side.",
  },
  activationRequestNotFound: {
    id: "error.activation-request-not-found",
    defaultMessage: "Your device activation request could not be found.",
    description: "error message: something went wrong server-side.",
  },
  activationValidationError: {
    id: "error.activation-validation-error",
    defaultMessage: "One or more of the details you have entered are invalid.",
    description: "error message: something went wrong server-side.",
  },
  invalidPostalCodeCountryCodeCombination: {
    id: "error.invalid-postal-code-country-code-combination",
    defaultMessage:
      "The post code you entered is not valid for the country you have selected.",
    description: "error message: something went wrong server-side.",
  },
  partnerRoleConflict: {
    id: "error.partner-role-conflict",
    defaultMessage:
      "You are currently logged in as a partner. Product must belong to a customer.",
    description: "error message: something went wrong server-side.",
  },
  invalidVolume: {
    id: "error.invalid-volume",
    defaultMessage: "The pool volume you have entered is invalid.",
    description: "error message: something went wrong server-side.",
  },
  validationError: {
    id: "error.generic-validation-error",
    defaultMessage: "One or more of the details you have entered are invalid.",
    description: "error message: something went wrong server-side.",
  },
  cannotRegisterADevice: {
    id: "error.cannot-register-a-device",
    defaultMessage:
      "Couldn't register your device right now. Please try again later.",
    description: "error message: something went wrong server-side.",
  },
  unknown: {
    id: "error.unknown",
    defaultMessage: "Communication failure. Please try again later.",
    description: "error message: something went wrong server-side.",
  },
});

function getErrorMessage(errorCode: ErrorCode | null): MessageDescriptor {
  switch (errorCode) {
    case ErrorCode.ActivationRequestNotFound:
      return messages.activationRequestNotFound;
    case ErrorCode.RegistrationCodeNotFound:
      return messages.registrationCodeNotFound;
    case ErrorCode.RegistrationCodeForIotDevice:
      return messages.registrationCodeForIotDevice;
    case ErrorCode.RegistrationCodeAlreadyRegistered:
      return messages.registrationCodeAlreadyRegistered;
    case ErrorCode.InvalidPersonCount:
      return messages.invalidPersonCount;
    case ErrorCode.InvalidRegistrationDetails:
      return messages.invalidRegistrationDetails;
    case ErrorCode.ActivationValidationError:
      return messages.activationValidationError;
    case ErrorCode.InvalidPostalCodeCountryCodeCombination:
      return messages.invalidPostalCodeCountryCodeCombination;
    case ErrorCode.PartnerRoleConflict:
      return messages.partnerRoleConflict;
    case ErrorCode.InvalidVolume:
      return messages.invalidVolume;
    case ErrorCode.ValidationError:
      return messages.validationError;
    case ErrorCode.CannotRegisterADevice:
      return messages.cannotRegisterADevice;
    case ErrorCode.Unknown:
    default:
      return messages.unknown;
  }
}

interface ErrorProps {
  show: boolean;
  errorCode: ErrorCode | null;
}

export default function Error({ show, errorCode }: ErrorProps) {
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      if (!ref.current) return;
      const top = ref.current?.offsetTop - 100; // sitecore header is 85px
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    }
  }, [show]);

  if (!show) return <Fragment />;

  const message = getErrorMessage(errorCode);

  return (
    <div ref={ref} className={style.root}>
      <div>
        <img className={style.icon} src={srcError} alt="" />
      </div>
      <div>
        <div className={style.title}>
          <FormattedMessage
            id="error.title"
            defaultMessage="There was a problem"
            description="title: error message shown when something has gone wrong which is not the users fault."
          />
        </div>
        <div className={style.body}>{formatMessage(message)}</div>
      </div>
    </div>
  );
}
