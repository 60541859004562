import React from "react";
import classnames from "classnames/bind";

import style from "./CheckBox.module.css";

const cx = classnames.bind(style);

interface CheckBoxProps {
  label: string | React.ReactNode;
  checked: boolean;
  onClick: (value: boolean) => void;
}

export default function CheckBox({ label, checked, onClick }: CheckBoxProps) {
  return (
    <div className={style.root} onClick={() => onClick(!checked)}>
      <div className={cx("checkbox", { checked })} />
      <label className={style.label}>{label}</label>
    </div>
  );
}
