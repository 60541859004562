export enum Co2Bottle {
  Bottle425g = "Bottle425g",
  Bottle2Kg = "Bottle2Kg",
  Bottle3Kg = "Bottle3Kg",
  Bottle5Kg = "Bottle5Kg",
  Bottle6Kg = "Bottle6Kg",
  Bottle10Kg = "Bottle10Kg",
}

export enum FilterCartridge {
  M200 = "M200",
  M300 = "M300",
  M400 = "M400",
  Mp200 = "Mp200",
  Mp300 = "Mp300",
  Mp400 = "Mp400",
  Mpc400 = "Mpc400",
  Mpc500 = "Mpc500",
  Tc200 = "Tc200",
}

export enum PoolMaterial {
  Concrete = "Concrete",
  Tiles = "Tiles",
  Foil = "Foil",
  Steel = "Steel",
  GlassFibre = "GlassFibre",
}

export enum PoolShape {
  Rectangular = "Rectangular",
  Kidney = "Kidney",
  Round = "Round",
  LShaped = "LShaped",
}

export enum PoolLocation {
  Outdoor = "Outdoor",
  Indoor = "Indoor",
}

export enum PoolDisinfectionType {
  Salt = "Salt",
  Chlorine = "Chlorine",
  Bromine = "Bromine",
}

export enum CustomerBenefits {
  MaintenanceAndInformation = "MaintenanceAndInformation",
  ServiceAndHelp = "ServiceAndHelp",
  TenYearWarranty = "TenYearWarranty",
  FreeService = "FreeService",
  VoucherHygieneBox = "VoucherHygieneBox",
  VoucherOnlineShop = "VoucherOnlineShop",
  VoucherPoolAgents = "VoucherPoolAgents",
  BestWaterHomeApp = "BestWaterHomeApp",
  BestWaterHomeAppPoolRobot = "BestWaterHomeAppPoolRobot",
  BestWaterHomeAppPoolTester = "BestWaterHomeAppPoolTester",
  BestWaterHomeAppJugs = "BestWaterHomeAppJugs",
}

export enum ProductType {
  Default = "Default",
  Pool = "Pool",
  WaterDispenser = "WaterDispenser",
  PerlaIoT = "PerlaIoT",
  SilkIoT = "SilkIoT",
}

export enum SpecificProductType {
  AqaDrinkPro20 = "AqaDrinkPro20",
  AquaLizer = "AquaLizer",
  BackwashFilter = "BackwashFilter",
  Cillit = "Cillit",
  E1 = "E1",
  Penguin = "Penguin",
  Perla = "Perla",
  PoolCareSet = "PoolCareSet",
  PoolRobot = "PoolRobot",
  AqaTotalEnergy = "AqaTotalEnergy",
  KitchenValves = "KitchenValves",
  Vida = "Vida",
  Generic = "Generic",
  GenericUK = "GenericUK",
}

export enum Gender {
  Company = "Company",
  Male = "Male",
  Female = "Female",
}

export type Profile = {
  id: number;
  userName: string;
  userSubjectId: string;
  isAccountVerified: boolean;
  prefix: string;
  suffix: string;
  organization: string;
  fax: string;
  uid: string;
  website: string;
  preferredLanguage: string;
  gender: Gender;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhoneNumber: string;
  telephone: string;
  street: string;
  city: string;
  postalCode: string;
  countryCode: string;
};

export type Product = {
  productCode: string;
  name: string;
  imageUrl: string;
  productType: ProductType;
  specificProductType: SpecificProductType;
  benefits: CustomerBenefits[];
  displayLegalNote: boolean;
};

type PoolProductRegistrations = {
  registrationId: number;
  lastUsedRegistrationCode: string;
  productId: number;
  productName: string;
  installationDate: string;
  poolId: number;
}[];

export type Pool = {
  poolProductRegistrations: PoolProductRegistrations;
  id: number;
  name: string;
  lastModification: string;
  volume: number;
  disinfectionType: PoolDisinfectionType;
  location: PoolLocation;
  countryCode: string;
  street: string;
  city: string;
  postalCode: string;
  material: PoolMaterial;
  shape: PoolShape;
};

export type Pools = Pool[];

export type Device = {
  requestId: string;
  trackingId: string;
  countryCode: string;
  languageCode: string;
  installerPartnerId: number;
  installationDate: string;
  isRegistered: boolean;
  firmwareVersion: number;
  deviceId: string;
  deviceType: string;
  deviceVersion: string;
  productName: string;
  productCode: string;
  address: {
    countryCode: string;
    postalCode: string;
    street: string;
    city: string;
  };
};

type DrinkPro20ShopLink = {
  countryCode: string;
  link: string | null;
  price: number | null;
  languageCode: string | null;
};

type Cartridge = {
  filterCartridge: FilterCartridge;
  supportsIC50: boolean;
  articleNumber: string;
  displayName: string;
  shopLinks: DrinkPro20ShopLink[];
};

type Bottle = {
  co2Bottle: Co2Bottle;
  requiredRoomVolume: number;
  articleNumber: string;
  displayName: string;
  shopLinks: DrinkPro20ShopLink[];
};

export type DrinkPro20Data = {
  cartridges: Cartridge[];
  co2Bottles: Bottle[];
};

type Country = {
  countryCode: string;
  name: string;
};

export type Countries = Country[];

export enum ErrorCode {
  // ProductRegistration
  RegistrationCodeNotFound = "1000",
  RegistrationCodeForIotDevice = "1001",
  RegistrationCodeAlreadyRegistered = "1002",
  InvalidPersonCount = "1003",
  InvalidRegistrationDetails = "1004",
  CannotRegisterADevice = "1005",

  //DeviceActivation
  ActivationRequestNotFound = "2000",
  ActivationValidationError = "2001",

  //Location
  InvalidPostalCodeCountryCodeCombination = "3000",

  //Role
  PartnerRoleConflict = "4000",

  //Pool
  InvalidVolume = "5000",
  ValidationError = "5001",

  //Other
  // extend me...
  Unknown = "9999",
}
