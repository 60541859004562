import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useLocation } from "react-router";

import {
  useExternalLinkItems,
  ExternalLinkItemType,
  LinkType,
} from "../data/externalLinks";
import getLocalisedAppStoreImage from "modules/util/getLocalisedAppStoreImage";

import Button from "modules/common/components/Button";

import style from "./ExternalLinks.module.css";

export const StoreLink = ({ link }: { link: LinkType }) => {
  const { locale } = useIntl();
  const language = locale.split("-")[0];
  const src = getLocalisedAppStoreImage(link.name, language);
  return (
    <div
      style={{ backgroundImage: `url(${src})` }}
      className={style.storeLink}
      onClick={() => window.open(link.url)}
    />
  );
};

function getLinkComponent(link: LinkType, idx: number) {
  if (link.type === "store") {
    return <StoreLink link={link} key={idx} />;
  } else {
    return (
      <Button
        key={idx}
        name={link.name}
        onClick={() => (link.url ? window.open(link.url) : undefined)}
        btnStyle="primary"
        size="small"
      />
    );
  }
}

interface ExternalLinkItemProps {
  item: ExternalLinkItemType;
}

const ExternalLinkItem = ({ item }: ExternalLinkItemProps) => {
  return (
    <div className={style.item}>
      <img src={item.img} className={style.img} alt="" />
      <div className={style.content}>
        <div>
          <div className={style.name}>{item.title}</div>
          <div className={style.desc}>{item.desc}</div>
        </div>
        <div className={style.links}>
          {item.links.map((link, idx) => getLinkComponent(link, idx))}
        </div>
      </div>
    </div>
  );
};

const ExternalLinks = () => {
  const { pathname } = useLocation();
  const externalLinkItems = useExternalLinkItems();
  const showTitle = pathname !== "/result";
  return (
    <div className={style.root}>
      {showTitle && (
        <div className={style.title}>
          <FormattedMessage
            id="external-links.title"
            defaultMessage="Enjoy the many benefits of a BWT account"
            description="title for external links section - download app, go to shop etc."
          />
        </div>
      )}
      <div className={style.items}>
        {externalLinkItems.map((item, idx) => (
          <ExternalLinkItem item={item} key={idx} />
        ))}
      </div>
    </div>
  );
};

export default ExternalLinks;
