declare global {
  interface Window {
    LOCALE_PREFIX: string;
  }
}

const localePrefix = window.LOCALE_PREFIX || "/de";
const redirectExt = localePrefix + "/api/sitecore/Account/Login";

const getBasename = (index: number): string => {
  const initialBasename = window.location.pathname
    .split("/")
    .slice(0, index)
    .join("/");
  const basename =
    initialBasename.slice(-1) === "/" ? initialBasename : initialBasename + "/";
  return basename;
};

const config: {
  basename: string;
  apiUrl: string;
  ajaxApiHeaders: {
    Authorization?: string;
    accept: string;
  };
  loginRedirect?: string;
} = {
  basename: "/",
  apiUrl: "/api/sitecore/ReactProxy/HandleCall?url=api/",
  ajaxApiHeaders: {
    accept: "*/*",
  },
};

if (window.location.hostname === "dev.bwt.com") {
  config.loginRedirect = "https://dev.bwt.com" + redirectExt;
  config.basename = getBasename(4);
} else if (window.location.hostname === "dev-customer.bwt.com") {
  config.loginRedirect = "https://dev-customer.bwt.com" + redirectExt;
  config.basename = getBasename(2);
} else if (window.location.hostname === "dev-myproduct.bwt.com") {
  config.loginRedirect = "https://dev-myproduct.bwt.com" + redirectExt;
  config.basename = getBasename(2);
} else if (window.location.hostname === "test.bwt.com") {
  config.loginRedirect = "https://test.bwt.com" + redirectExt;
  config.basename = getBasename(4);
} else if (window.location.hostname === "test-customer.bwt.com") {
  config.loginRedirect = "https://test-customer.bwt.com" + redirectExt;
  config.basename = getBasename(2);
} else if (window.location.hostname === "test-myproduct.bwt.com") {
  config.loginRedirect = "https://test-myproduct.bwt.com" + redirectExt;
  config.basename = getBasename(2);
} else if (["bwt.bwt.com", "www.bwt.com"].includes(window.location.hostname)) {
  config.loginRedirect = "https://bwt.com" + redirectExt;
  config.basename = getBasename(4);
} else if (
  ["customer.bwt.com", "preview-customer.bwt.com"].includes(
    window.location.hostname
  )
) {
  config.loginRedirect = "https://customer.bwt.com" + redirectExt;
  config.basename = getBasename(2);
} else if (
  ["myproduct.bwt.com", "preview-myproduct.bwt.com"].includes(
    window.location.hostname
  )
) {
  config.loginRedirect = "https://myproduct.bwt.com" + redirectExt;
  config.basename = getBasename(2);
} else {
  config.apiUrl = "/api";
  if (window.location.hostname === "localhost") {
    config.apiUrl = "https://bwt-dev-customerweb.azurewebsites.net/api/";
  }
  const token = prompt("Please enter a valid bearer token:");
  config.ajaxApiHeaders.Authorization = `Bearer ${token}`;
}

export default config;
