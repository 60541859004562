import { SpecificProductType } from "modules/form/data/types";

import srcAQATotalEnergyFamily from "assets/products/category/AQA-Total-Energy-Family.png";
import srcAQUAlizer from "assets/products/category/AQUAlizer.png";
import srcBackwashFilter from "assets/products/category/Backwashfilter.png";
import srcCillitFamily from "assets/products/category/Cillit.png";
import srcKitchenValvesFamily from "assets/products/category/Kitchen-valves.png";
import srcPenguin from "assets/products/category/Penguin.png";
import srcPerlaFamily from "assets/products/category/Perla-Family.png";
import srcPoolTestKit from "assets/products/category/Poolcareset.png";
import srcPoolRobotFamily from "assets/products/category/Poolrobots.png";
import srcE1Family from "assets/products/category/Single-lever-filter-E1.png";
import srcVida from "assets/products/category/Vida.png";
import srcDrinkPro20 from "assets/products/category/drink-pro-20.png";
import srcGenericUK from "assets/products/category/Softener-UK.png";
import srcGeneric from "assets/products/category/Generic-main-old.png";

export default function getProductCategoryImage(
  specificProductType?: SpecificProductType
) {
  switch (specificProductType) {
    case SpecificProductType.AqaDrinkPro20:
      return srcDrinkPro20;
    case SpecificProductType.AquaLizer:
      return srcAQUAlizer;
    case SpecificProductType.BackwashFilter:
      return srcBackwashFilter;
    case SpecificProductType.Cillit:
      return srcCillitFamily;
    case SpecificProductType.E1:
      return srcE1Family;
    case SpecificProductType.Penguin:
      return srcPenguin;
    case SpecificProductType.Perla:
      return srcPerlaFamily;
    case SpecificProductType.PoolCareSet:
      return srcPoolTestKit;
    case SpecificProductType.PoolRobot:
      return srcPoolRobotFamily;
    case SpecificProductType.AqaTotalEnergy:
      return srcAQATotalEnergyFamily;
    case SpecificProductType.KitchenValves:
      return srcKitchenValvesFamily;
    case SpecificProductType.Vida:
      return srcVida;
    case SpecificProductType.GenericUK:
      return srcGenericUK;
    case SpecificProductType.Generic:
      return srcGeneric;
    default:
      return "";
  }
}
