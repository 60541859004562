export default [
  {
    countryCode: "AT",
    name: "Austria",
  },
  {
    countryCode: "BE",
    name: "Belgium",
  },
  {
    countryCode: "CH",
    name: "Switzerland",
  },
  {
    countryCode: "CN",
    name: "China",
  },
  {
    countryCode: "DE",
    name: "Germany",
  },
  {
    countryCode: "DK",
    name: "Denmark",
  },
  {
    countryCode: "ES",
    name: "Spain",
  },
  {
    countryCode: "FI",
    name: "Finland",
  },
  {
    countryCode: "FR",
    name: "France",
  },
  {
    countryCode: "GB",
    name: "United Kingdom",
  },
  {
    countryCode: "HU",
    name: "Hungary",
  },
  {
    countryCode: "IT",
    name: "Italy",
  },
  {
    countryCode: "LU",
    name: "Luxembourg",
  },
  {
    countryCode: "NL",
    name: "Netherlands",
  },
  {
    countryCode: "NO",
    name: "Norway",
  },
  {
    countryCode: "PL",
    name: "Poland",
  },
  {
    countryCode: "SE",
    name: "Sweden",
  },
  {
    countryCode: "UA",
    name: "Ukraine",
  },
];
