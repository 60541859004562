import React from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";

import Button from "modules/common/components/Button";

import style from "./NoProductCodeHelper.module.css";

const messages = defineMessages({
  button: {
    id: "no-product-code-helper.button",
    defaultMessage: "How it works",
    description:
      "button: link to page that shows you how to register product without product code",
  },
});

const NoProductCodeHelper: React.FC<{}> = () => {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  return (
    <div className={style.root}>
      <div className={style.title}>
        <FormattedMessage
          id="no-product-code-helper.title"
          defaultMessage="You do not have a product code?"
          description="Title of helper if you don't have a product code"
        />
      </div>
      <div className={style.body}>
        <FormattedMessage
          id="no-product-code-helper.body"
          defaultMessage="You can also register your product without a code."
          description="Body of helper if you don't have a product code"
        />
      </div>
      <Button
        name={formatMessage(messages.button)}
        btnStyle="secondary"
        size="tiny"
        onClick={() => push("/noproductcode")}
      />
    </div>
  );
};

export default NoProductCodeHelper;
