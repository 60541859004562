import React, { useState, useEffect } from "react";
import { useIntl, defineMessages } from "react-intl";

import { useForm } from "./FormContext";
import { useAuth } from "modules/auth/components/AuthProvider";
import { getSupportedCountries } from "modules/api/endpoints";
import defaultSupportedCountries from "../data/supportedCountries";
import { Countries } from "../data/types";

import TextInput from "modules/common/components/TextInput";
import CategoryTitle from "modules/common/components/CategoryTitle";
import DropDown from "modules/common/components/DropDown";
import { validatePostCode } from "../data/validation";

const messages = defineMessages({
  title: {
    id: "product-location.title",
    defaultMessage: "Product Location",
    description: "category title for entering the location of the product.",
  },
  address: {
    id: "product-location.labels.address",
    defaultMessage: "Address",
    description: "label: address input - house number, street name etc.",
  },
  postCode: {
    id: "product-location.labels.postCode",
    defaultMessage: "Post code",
    description: "label: post code input.",
  },
  city: {
    id: "product-location.labels.city",
    defaultMessage: "Location",
    description: "label: city/town input.",
  },
  country: {
    id: "product-location.labels.country",
    defaultMessage: "Country",
    description: "label: selecting country",
  },
});

export default function ProductLocation() {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();
  const {
    state: { profile, isLoggedIn },
  } = useAuth();

  const initialState = defaultSupportedCountries.map((country) => {
    const path = country.countryCode.toLocaleLowerCase();
    const translatedName = formatMessage({
      id: path,
      defaultMessage: country.name,
    });

    return {
      countryCode: country.countryCode,
      name: translatedName,
    };
  });

  const [countries, setCountries] = useState<Countries>(initialState);

  const validPostCode = validatePostCode(
    state.form.postCode,
    state.form.country
  );

  useEffect(() => {
    if (profile) {
      setInState("form", "street", profile.street);
      setInState("form", "city", profile.city);
      setInState("form", "postCode", profile.postalCode);
      setInState("form", "country", profile.countryCode);
    }
    const runEffect = async () => {
      if (!isLoggedIn) return;
      const { success, data } = await getSupportedCountries();
      if (success) {
        if (data) {
          setCountries(data);
        }
      }
    };
    runEffect();
  }, [profile, setInState]);

  return (
    <div>
      <CategoryTitle title={formatMessage(messages.title)} />
      <TextInput
        label={formatMessage(messages.address)}
        defaultValue={state.form.street}
        update={(street: string) => setInState("form", "street", street)}
      />
      <TextInput
        label={formatMessage(messages.postCode)}
        defaultValue={state.form.postCode}
        update={(postCode: string) => setInState("form", "postCode", postCode)}
        narrow
        errorOverride={!validPostCode}
      />
      <TextInput
        label={formatMessage(messages.city)}
        defaultValue={state.form.city}
        update={(city: string) => setInState("form", "city", city)}
      />
      <DropDown
        label={formatMessage(messages.country)}
        list={countries
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((c) => {
            return {
              label: c.name,
              value: c.countryCode,
            };
          })}
        defaultValue={
          (
            countries?.find(
              (country) => country.countryCode === state.form.country
            ) || countries[0]
          )?.name
        }
        update={(country: string) => setInState("form", "country", country)}
      />
    </div>
  );
}
