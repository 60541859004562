import React, {} from "react";
import { defineMessages, useIntl } from "react-intl";

import { useForm } from "./FormContext";


import Button from "modules/common/components/Button";

import style from "./ModalContent.module.css";

const messages = defineMessages({
  label: {
    id: "service.modal.label",
    defaultMessage:
      "Are you sure you don't want to take advantage of our free 2 year service?",
    description: "popup for not accepting service - H1",
  },
  label2: {
    id: "service.modal.label2",
    defaultMessage:
      "The agreement is free of charge for the first 2 years from commissioning.",
    description: "popup for not accepting service - H2",
  },
  termsButton: {
    id: "link-to-terms.button",
    defaultMessage: "Link to Terms",
    description: "button text: link to service terms.",
  },
  notice: {
    id: "service.modal.notice",
    defaultMessage:
      "You can change it to another guarantee contract at any time or terminate the contract with 30 days' notice.",
    description: "cancellation notice for the service",
  },
  yes: {
    id: "get-service.button",
    defaultMessage: "Get the Free Service",
    description: "button text: get the free service.",
  },
  no: {
    id: "no-service.button",
    defaultMessage: "I Am Sure",
    description: "button text: sure i dont want the free service",
  },
});

interface ModalButtonProps {
  buttonNo: { title: string; click: () => void }[];
  buttonYes: { title: string; click: () => void }[];
}

const ModalContent: React.FC<ModalButtonProps> = ({ buttonNo, buttonYes }) => {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();

  return (
    <div className={style.root}>
      <h1 className={style.label}>{formatMessage(messages.label)}</h1>
      <h2 className={style.label}>{formatMessage(messages.label2)}</h2>
      <div className={style.btnPositioning}>
        <div className={style.btn}>
          <a
            href="https://www.bwt.com/da-dk/service-til-privat/tryghedsaftaler/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatMessage(messages.termsButton)}
          </a>
        </div>
        <p className={style.label}>{formatMessage(messages.notice)}</p>
      </div>
      <div className={style.positioning}>
        {buttonNo.map((button, idx) => (
          <div className={style.btn}>
            <Button
              key={idx}
              onClick={button.click}
              name={button.title}
              btnStyle="secondary"
            ></Button>
          </div>
        ))}
        {buttonYes.map((button, idx) => (
          <div className={style.btn}>
            <Button
              key={idx}
              onClick={button.click}
              name={button.title}
              btnStyle="primary"
            ></Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModalContent;
