import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

import style from "./LegalNotice.module.css";

interface LegalNoticeProps {
  display: boolean;
}

const LegalNotice = ({ display }: LegalNoticeProps) => {
  if (!display) return <Fragment />;
  return (
    <div className={style.root}>
      <FormattedMessage
        id="benefits_legal-notice"
        defaultMessage="* Depending on the country: Registration advantages may vary."
        description="legal notice for product benefits per country."
      />
    </div>
  );
};

export default LegalNotice;
