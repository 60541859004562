import { instance } from "./config";

import {
  Co2Bottle,
  FilterCartridge,
  PoolMaterial,
  PoolShape,
  PoolLocation,
  PoolDisinfectionType,
  ErrorCode,
  Profile,
  Pools,
  Product,
  Pool,
  Countries,
  DrinkPro20Data,
  Device,
} from "modules/form/data/types";
import { AxiosError, AxiosResponse } from "axios";

type ResponseEnvelope<T> = {
  success: boolean;
  data: T | null;
  errorCode: ErrorCode | null;
  unauthorized: boolean;
};

function resolveError(error: AxiosError) {
  const errorCode = error.response?.data.ErrorCode || ErrorCode.Unknown;
  const unauthorized = error.response?.status === 401;
  return { success: false, data: null, errorCode, unauthorized };
}

function resolveSuccess<T>(res: AxiosResponse): ResponseEnvelope<T> {
  const success = res.status === 200;
  const data = success ? res.data : null;
  const errorCode = success ? null : ErrorCode.Unknown;
  return { success, data, errorCode, unauthorized: false };
}

export async function checkUserIsLoggedIn() {
  try {
    const res = await instance.get("Authorization/test");
    return resolveSuccess(res);
  } catch (error) {
    return resolveError(error);
  }
}

export async function checkProductCode(productCode: string) {
  try {
    const res = await instance.get(`Product/code/${productCode}`);
    return resolveSuccess<Product>(res);
  } catch (error) {
    return resolveError(error);
  }
}

export async function fetchPools() {
  try {
    const res = await instance.get("Pool");
    return resolveSuccess<Pools>(res);
  } catch (error) {
    return resolveError(error);
  }
}

type RegisterPoolArgs = {
  name: string;
  volume: number;
  disinfectionType: PoolDisinfectionType;
  location: PoolLocation;
  shape: PoolShape;
  material: PoolMaterial;
  countryCode: string;
  street: string;
  city: string;
  postalCode: string;
  acceptedContractTerms?: boolean;
  acceptedContractDate?: string | null;
};

export async function registerPool(args: RegisterPoolArgs) {
  try {
    const res = await instance.post("Pool", args);
    return resolveSuccess<Pool>(res);
  } catch (error) {
    return resolveError(error);
  }
}

export async function getSupportedCountries() {
  try {
    const res = await instance.get("General/supportedCountries");
    return resolveSuccess<Countries>(res);
  } catch (error) {
    return resolveError(error);
  }
}

export async function getDrinkPro20Data() {
  try {
    const res = await instance.get("Product/aqadrink");
    return resolveSuccess<DrinkPro20Data>(res);
  } catch (error) {
    return resolveError(error);
  }
}

type RegisterPoolProductArgs = {
  poolId: number;
  productCode: string;
  installationDate: string;
  isDataShared: boolean;
};

export async function registerPoolProduct(args: RegisterPoolProductArgs) {
  try {
    const res = await instance.post("Product/register/pool", args);
    return resolveSuccess(res);
  } catch (error) {
    return resolveError(error);
  }
}

type RegisterProductArgs = {
  productCode: string;
  installationDate: string;
  street: string;
  city: string;
  postalCode: string;
  countryCode: string;
  shareDataWithPartner: boolean;
  personCount: number;
  displayName: string;
  acceptedContractTerms?: boolean;
  acceptedContractDate?: string;
};

export async function registerProduct(args: RegisterProductArgs) {
  try {
    const res = await instance.post("Product/register", args);
    return resolveSuccess(res);
  } catch (error) {
    return resolveError(error);
  }
}

export async function getUserProfile() {
  try {
    const res = await instance.get("User/profile");
    return resolveSuccess<Profile>(res);
  } catch (error) {
    return resolveError(error);
  }
}

type AqaDrinkProductArgs = {
  displayName: string;
  filterCartridge: FilterCartridge;
  co2Bottle: Co2Bottle;
  roomVolume: number;
  hasIc50: boolean;
  dailySodaConsumption: number;
  dailyWaterConsumption: number;
  productCode: string;
  installationDate: string;
  street: string;
  city: string;
  postalCode: string;
  countryCode: string;
  shareDataWithPartner: boolean;
  personCount: number;
  acceptedContractTerms?: boolean,
  acceptedContractDate?: string;
};

export async function registerAqaDrinkProduct(args: AqaDrinkProductArgs) {
  try {
    const res = await instance.post("Product/register/aqadrink", args);
    return resolveSuccess(res);
  } catch (error) {
    return resolveError(error);
  }
}

export async function getDeviceActivationRequest(requestId: string) {
  try {
    const res = await instance.get(`Device/activation/${requestId}`);
    return resolveSuccess<Device>(res);
  } catch (error) {
    return resolveError(error);
  }
}

type ApproveDeviceActivationArgs = {
  requestId: string;
  deviceDisplayName: string;
  acceptTerms: boolean;
  allowSendData: boolean;
  shareDeviceWithPlumber: boolean;
  street: string;
  city: string;
  postalCode: string;
  countryCode: string;
};

export async function approveDeviceActivationRequest(
  args: ApproveDeviceActivationArgs
) {
  try {
    const res = await instance.post("Device/activation/approve", args);
    return resolveSuccess(res);
  } catch (error) {
    return resolveError(error);
  }
}

export async function declineDeviceActivationRequest(requestId: string) {
  try {
    const res = await instance.post(`Device/activation/decline/${requestId}`);
    return resolveSuccess(res);
  } catch (error) {
    return resolveError(error);
  }
}
