import { useLocation } from "react-router";

type LocationQuery = Record<string, string | undefined>;

export function useLocationQuery(): LocationQuery {
  const { search } = useLocation();
  const query = search.substring(1);
  const vars = query.split("&");
  const out: LocationQuery = {};
  for (let i = 0; i < vars.length; i++) {
    const [key, value = ""] = vars[i].split("=");
    out[decodeURIComponent(key)] = decodeURIComponent(value);
  }
  return out;
}
