import React from "react";
import classnames from "classnames/bind";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { useForm } from "modules/form/components/FormContext";
import { validateNumber } from "../../data/validation";

import NumericInput from "modules/common/components/NumericInput";

import style from "./PoolVolume.module.css";
import CircleIndicator from "modules/common/components/CircleIndicator";
const cx = classnames.bind(style);

const messages = defineMessages({
  label: {
    id: "pool-volume.label",
    defaultMessage: "Volume in m3",
    description: "label: pool volume - measured in metres cubed",
  },
});

const PoolVolume = () => {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();

  const volume = state?.pool?.volume;
  const length = state?.pool?.length;
  const width = state?.pool?.width;
  const depth = state?.pool?.depth;

  const validVolume = validateNumber(volume);
  const validLength = validateNumber(length);
  const validWidth = validateNumber(width);
  const validDepth = validateNumber(depth);

  const lengthErrored = validLength ? (length ?? 0) < 1 : false;
  const widthErrored = validWidth ? (width ?? 0) < 1 : false;
  const depthErrored = validDepth ? (depth ?? 0) < 1 : false;

  const calcInputsDisabled = validVolume;
  const volumeInputDisabled = validLength || validWidth || validDepth;

  return (
    <div className={style.root}>
      <NumericInput
        label={formatMessage(messages.label)}
        update={(volume) => setInState("pool", "volume", volume)}
        disabled={volumeInputDisabled}
        defaultValue={state.pool?.volume}
      />
      <div className={style.divide}>
        <FormattedMessage
          id="divide"
          defaultMessage="or"
          description="connect different possibilties."
        />
      </div>
      <div className={style.cont}>
        <div className={cx(style.calcSeg, { disabled: calcInputsDisabled })}>
          <div className={style.placeholder}>
            <FormattedMessage
              id="pool-volume.label.volume"
              defaultMessage="Length, width, depth in m"
              description="input label for calculating volume of a swimming pool."
            />
          </div>
          <div className={style.inputGroup}>
            <input
              className={cx(style.input, { errored: lengthErrored })}
              onChange={(e) =>
                setInState("pool", "length", parseFloat(e.target.value))
              }
              type="number"
              pattern="[0-9]*"
              disabled={calcInputsDisabled}
            />
            <div className={style.x}>x</div>
            <input
              className={cx(style.input, { errored: widthErrored })}
              onChange={(e) =>
                setInState("pool", "width", parseFloat(e.target.value))
              }
              type="number"
              pattern="[0-9]*"
              disabled={calcInputsDisabled}
            />
            <div className={style.x}>x</div>
            <input
              className={cx(style.input, { errored: depthErrored })}
              onChange={(e) =>
                setInState("pool", "depth", parseFloat(e.target.value))
              }
              type="number"
              pattern="[0-9]*"
              disabled={calcInputsDisabled}
            />
          </div>
        </div>
        <div className={style.circle}>
          <CircleIndicator
            show={validLength && validWidth && validDepth}
            completed={!lengthErrored && !widthErrored && !depthErrored}
            errored={lengthErrored || widthErrored || depthErrored}
          />
        </div>
      </div>
    </div>
  );
};

export default PoolVolume;
