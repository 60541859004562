import React, { useState, useMemo, useEffect } from "react";

import style from "./PopUpProvider.module.css";


type DisplayFunc = (
  content: React.ReactNode,
  showClose?: boolean,
  hide?: () => void
) => void;
interface ContextState {
  display: DisplayFunc;
}

interface PopUpState {
  content: React.ReactNode;
  showClose: boolean;
  hide: (() => void) | null;
}

const initalState: PopUpState = {
  content: null,
  showClose: true,
  hide: null,
};

export const PopUpContext = React.createContext<ContextState>({
  display: () => {},
});

const buildSetter = (
  setState: React.Dispatch<React.SetStateAction<PopUpState>>
) => (content: React.ReactNode, showClose?: boolean, hide?: () => void) =>
  setState({ content, hide: hide ?? null, showClose: showClose ?? true });

const PopUp: React.FC = ({ children }) => {
  const [{ content, hide, showClose }, setState] = useState(initalState);

  const display = useMemo(() => buildSetter(setState), [setState]);

  useEffect(() => {
    if (content) window.scrollTo(0, 0);
  }, [content]);

  return (
    <PopUpContext.Provider value={{ display }}>
      <div className={content ? style.interactionDisabled : ""}>{children}</div>
      {content && (
        <div className={style.root}>
          <div className={style.frame}>
            {showClose && (
              <div className={style.close} onClick={hide ?? undefined} />
            )}
            <div className={style.content}>{content}</div>
          </div>
        </div>
          )}
    </PopUpContext.Provider>
  );
};

export function usePopUp(): ContextState {
  const { display } = React.useContext(PopUpContext);
  return { display };
}

export default PopUp;
