import React, { useEffect, Fragment } from "react";
import { useHistory } from "react-router";
import { useAuth } from "modules/auth/components/AuthProvider";
import { useForm } from "./FormContext";
import config from "config";

export default function Redirect() {
  const { push } = useHistory();
  const {
    state: { isLoggedIn },
  } = useAuth();
  const {
    state: { form, lastProductResponse },
  } = useForm();

  useEffect(() => {
    if (isLoggedIn || !config.loginRedirect) {
      push("/details");
    } else {
      localStorage.setItem(
        "cached-form-data",
        JSON.stringify({
          productCode: form.productCode,
          installationDate: form.installationDate,
          shareDataWithPartner: form.shareDataWithPartner,
          lastProductResponseData: lastProductResponse?.data,
        })
      );
      window.location.href = config.loginRedirect;
    }
  }, [isLoggedIn, push, form, lastProductResponse]);

  return <Fragment />;
}
