import React from "react";
import classnames from "classnames/bind";
import { FormattedMessage } from "react-intl";

import { useForm } from "./FormContext";
import { useBenefits } from "../data/benefits";

import CircleIndicator from "modules/common/components/CircleIndicator";
import LegalNotice from "./LegalNotice";

import style from "./Benefits.module.css";

const cx = classnames.bind(style);

interface ItemProps {
  icon: string;
  heading: string;
  message: string;
  specific: boolean;
}

const BenefitItem = ({ icon, heading, message, specific }: ItemProps) => {
  return (
    <div className={style.item}>
      <img src={icon} className={style.icon} alt="" />
      <div className={style.seg}>
        <div className={style.heading}>
          {specific && <CircleIndicator fixed={true} />}
          <span
            className={cx(style.headingText, {
              margLeft: specific,
            })}
          >
            {heading}
          </span>
        </div>
        <div className={style.msg}>{message}</div>
      </div>
    </div>
  );
};

const Benefits = () => {
  const { state } = useForm();
  const { benefits, displayLegalNote } = useBenefits();

  const lastProductResponseData = state?.lastProductResponse?.data || {};
  const specific = !!lastProductResponseData;

  return (
    <div className={cx("root", { specific })}>
      <div className={style.title}>
        <FormattedMessage
          id="benefits.title"
          defaultMessage="Your benefits:"
          description="title for benefits section."
        />
      </div>
      <div>
        {benefits.map((b, idx) => (
          <div>
            {b && (
              <BenefitItem
                key={idx}
                icon={b.icon}
                heading={b.heading}
                message={b.message}
                specific={specific}
              />
            )}
          </div>
        ))}
        <LegalNotice display={displayLegalNote} />
      </div>
    </div>
  );
};

export default Benefits;
