import React, { RefObject } from "react";
import { Route } from "react-router";

import FormContainer from "./FormContainer";
import ProductRegistration from "./ProductRegistration";
import Redirect from "./Redirect";
import Benefits from "./Benefits";
import ProductDetails from "./ProductDetails";

const ProductRegistrationContainer = () => (
  <>
    <ProductRegistration />
    <Benefits />
  </>
);

const ProductDetailsContainer = () => (
  <>
    <ProductDetails />
    <Benefits />
  </>
);

interface FormRoutesProps {
  formRef: RefObject<HTMLDivElement>;
}

export default function FormRoutes({ formRef }: FormRoutesProps) {
  return (
    <FormContainer formRef={formRef}>
      <Route exact path="/" component={ProductRegistrationContainer} />
      <Route path="/redirect" component={Redirect} />
      <Route path="/details" component={ProductDetailsContainer} />
    </FormContainer>
  );
}
