import React from "react";
import { FormattedMessage } from "react-intl";
import cx from "classnames";

import { useForm } from "../FormContext";

import CheckBox from "modules/common/components/CheckBox";

import style from "./AgreeDataProcessing.module.css";

export const AgreeDataProcessingOverlay = () => {
  return (
    <div>
      <div className={style.title}>
        <FormattedMessage
          id="pop-up.agree-data-processing.title"
          defaultMessage="Data protection notice"
          description="use of data"
        />
      </div>
      <div className={style.spacer} />
      <div className={style.text}>
        <FormattedMessage
          id="pop-up.agree-data-processing.paragraph1"
          defaultMessage="We are pleased that you are interested in digital services from BWT. The protection of your privacy is particularly important to us. It is therefore a matter of course for us that your personal information will be treated confidentially in accordance with the applicable data protection regulations."
          description="use of data"
        />
      </div>
      <div className={style.spacer} />
      <div className={cx(style.text, style.bold)}>
        <FormattedMessage
          id="pop-up.agree-data-processing.heading.paragraph2"
          defaultMessage="Collection and processing of personal data"
          description="use of data"
        />
      </div>
      <div className={style.text}>
        <FormattedMessage
          id="pop-up.agree-data-processing.paragraph2"
          defaultMessage="In order to be able to use BWT's digital services, we need and store certain information from you. This includes data you have contributed yourself, such as Your first and last name, your address, water hardness, your device location, possibly your personal installer or drinking water professional."
          description="use of data"
        />
      </div>
      <div className={style.spacer} />
      <div className={style.text}>
        <FormattedMessage
          id="pop-up.agree-data-processing.paragraph3"
          defaultMessage="Your -Perla III test- contains data storage in its electronic components, which store technical information about the device status, events and error messages. This information documents the status of a component / system / module, e.g. Status messages of the device, malfunctions and defects in important system components as well as fill levels (e.g. Perla tabs). This data is displayed on the device by a status message. Furthermore, these technical data are used to optimize functions and to identify and correct errors. In addition, relevant technical data is sent to BWT for the provision of service and repair processes, for documentation purposes (e.g. for the electronic service history) as well as for quality improvement and product optimization. These essentially consist of device master data (e.g. device identification number, commissioning date)."
          description="use of data"
        />
      </div>
      <div className={style.spacer} />
      <div className={cx(style.text, style.bold)}>
        <FormattedMessage
          id="pop-up.agree-data-processing.heading.paragraph4"
          defaultMessage='Partner approval "My installer"'
          description="use of data"
        />
      </div>
      <div className={style.text}>
        <FormattedMessage
          id="pop-up.agree.data-processing.paragraph4"
          defaultMessage="By choosing the option to release to the partner of your choice, you give your consent to the transmission of the current device status and the display of upcoming maintenance dates and messages. In addition, the partner receives a graphic display (map view), on which the device location and status in connection with your data (first name, last name, address, telephone and email address) is displayed."
          description="use of data"
        />
      </div>
      <div className={style.spacer} />
      <div className={cx(style.text, style.bold)}>
        <FormattedMessage
          id="pop-up.agree.data-processing.heading.paragraph5"
          defaultMessage="Use and disclosure of personal data"
          description="use of data"
        />
      </div>
      <div className={style.text}>
        <FormattedMessage
          id="pop-up.agree.data-processing.paragraph5"
          defaultMessage="We use your personal data for the following purposes:"
          description="use of data"
        />
        <div className={style.spacer} />
      </div>
      <div className={style.text}>
        <FormattedMessage
          id="pop-up.agree.data-processing.paragraph6"
          defaultMessage="To be able to look after you individually."
          description="use of data"
        />
      </div>
      <div className={style.text}>
        <FormattedMessage
          id="pop-up.agree.data-processing.paragraph7"
          defaultMessage="To be able to identify you when you contact us."
          description="use of data"
        />
      </div>
      <div className={style.text}>
        <FormattedMessage
          id="pop-up.agree.data-processing.paragraph8"
          defaultMessage='In order to be able to provide you with products and services. To improve the quality of our products and services. To activate the release of the device status for the responsible installer and to show the location of your device on a map (if you click the checkbox "Grant device status to installer"). By registering your -Perla III test- you agree that BWT Holding GmbH collects, processes and uses your personal data and may pass it on to its associated companies and affiliated companies for the above-mentioned purposes. If the declaration of consent also includes transmission to the drinking water professional or installer you have defined, your data can be passed on to them.'
          description="use of data"
        />
      </div>
      <div className={style.spacer} />
      <div className={cx(style.text, style.bold)}>
        <FormattedMessage
          id="pop-up.agree.data-processing.heading.paragraph9"
          defaultMessage="Right to object and contact"
          description="use of data"
        />
      </div>
      <div className={style.text}>
        <FormattedMessage
          id="pop-up.agree.data-processing.paragraph9"
          defaultMessage="Of course, you can revoke your consent to data processing at any time with effect for the future. However, the respective digital services can then no longer be used. On request, we can provide you with information about all personal data that we have stored about you. Furthermore, you have the right to have your data corrected or deleted at any time. For the revocation, a request for information or questions about this data protection declaration and the processing of your personal data, you can contact us at the following address:"
          description="use of data"
        />
      </div>
      <div className={style.spacer} />
      <div>
        <div className={style.text}>BWT Holding GmbH</div>
        <div className={style.text}>Walter Simmer-Straße 4</div>
        <div className={style.text}>5310 Mondsee</div>
        <div className={style.text}>AUSTRIA</div>
        <div className={style.spacer} />
        <FormattedMessage
          id="pop-up.agree.data-processing.email"
          defaultMessage="E-Mail: privacy@bwt-group.com"
          description="BWT e-mail address for privacy related enquires."
        />
      </div>
    </div>
  );
};

const Label = ({
  setShow,
}: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <div>
    <FormattedMessage
      id="agree-data-processing.label.seg1"
      defaultMessage="I have read the"
      description="first segment of data processing text."
    />{" "}
    <span style={{ textDecoration: "underline" }} onClick={() => setShow(true)}>
      <FormattedMessage
        id="agree-data-processing.label.seg2"
        defaultMessage="use of my data"
        description="second segment of data processing text."
      />
    </span>{" "}
    <FormattedMessage
      id="agree-data-processing.label.seg3"
      defaultMessage="and agree to the described processing of my data. "
      description="third segment of data processing text."
    />
  </div>
);

export default function AgreeDataProcessing({
  setShow,
}: {
  setShow: () => void;
}) {
  const { state, setInState } = useForm();
  return (
    <div className={style.root}>
      <CheckBox
        label={<Label setShow={setShow} />}
        checked={state.iot?.agreeDataProcessing ?? false}
        onClick={(agreeDataProcessing: boolean) =>
          setInState("iot", "agreeDataProcessing", agreeDataProcessing)
        }
      />
    </div>
  );
}
