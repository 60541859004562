import {
  FormContextState,
  FormState,
  PoolState,
  DrinkPro20State,
  IoTState,
  initialState,
} from "../components/FormContext";
import { Product, Device } from "../data/types";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  UpdateForm = "UPDATE_FORM",
  ResetForm = "RESET_FORM",
}

export enum Category {
  form = "form",
  pool = "pool",
  drinkPro20 = "drinkPro20",
  iot = "iot",
  lastProductResponse = "lastProductResponse",
  lastDeviceResponse = "lastDeviceResponse",
}

type UpdateFormPayload = {
  category: Category;
  key: string;
  value: string | number | Date | boolean | Product | Device | undefined;
};

type FormPayload = {
  [Types.UpdateForm]: UpdateFormPayload;
  [Types.ResetForm]: null;
};

export type FormActions = ActionMap<FormPayload>[keyof ActionMap<FormPayload>];

export const formReducer = (state: FormContextState, action: FormActions) => {
  switch (action.type) {
    case Types.UpdateForm: {
      const { category, key, value } = action.payload;
      const castKey: keyof FormContextState = category;
      const oldProps: FormState | PoolState | DrinkPro20State | IoTState | any =
        state[castKey];

      return {
        ...state,
        [category]: { ...oldProps, [key]: value },
      };
    }
    case Types.ResetForm: {
      return initialState;
    }
    default:
      return state;
  }
};
