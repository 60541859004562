import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";

import { useForm } from "../FormContext";
import {
  PoolLocation,
  PoolDisinfectionType,
  PoolMaterial,
  PoolShape,
} from "../../data/types";

import CategoryTitle from "modules/common/components/CategoryTitle";
import TextInput from "modules/common/components/TextInput";
import CheckBox from "modules/common/components/CheckBox";
import PoolVolume from "./PoolVolume";
import DropDown from "modules/common/components/DropDown";

import srcGoBack from "assets/IC_Go_Back.svg";

import style from "./CreatePoolForm.module.css";

const messages = defineMessages({
  title: {
    id: "create-pool-form.title",
    defaultMessage: "Create new pool",
    description: "title: creating a new pool.",
  },
  name: {
    id: "create-pool-form.label.pool-name",
    defaultMessage: "Name",
    description: "label: pool name.",
  },
  location: {
    id: "create-pool-form.sub-title.pool-location",
    defaultMessage: "Location",
    description: "sub title: pool location - indoor/outdoor.",
  },
  outdoor: {
    id: "create-pool-form.label.outdoor-pool",
    defaultMessage: "Outdoor pool",
    description: "label: pool is outdoors.",
  },
  indoor: {
    id: "create-pool-form.label.indoor-pool",
    defaultMessage: "Indoor pool",
    description: "label: pool is indoors.",
  },
  disinfection: {
    id: "create-pool-form.sub-title.disinfection-type",
    defaultMessage: "Type of Disinfection",
    description:
      "sub title: type of disinfection used in the pool - bromine/chlorine/salt.",
  },
  bromine: {
    id: "create-pool-form.label.bromine",
    defaultMessage: "Bromine",
    description: "label: disinfection type - bromine.",
  },
  chlorine: {
    id: "create-pool-form.label.chlorine",
    defaultMessage: "Chlorine",
    description: "label: disinfection type - chlorine.",
  },
  salt: {
    id: "create-pool-form.label.salt",
    defaultMessage: "Salt",
    description: "label: disinfection type - salt.",
  },
  material: {
    id: "create-pool-form.title.material",
    defaultMessage: "Material",
    description: "title: material of pool.",
  },
  steel: {
    id: "create-pool-form.drop-down-option.material.stainless-steel",
    defaultMessage: "Stainless Steel",
    description: "pool material",
  },
  tiles: {
    id: "create-pool-form.drop-down-option.material.tiles",
    defaultMessage: "Tiles",
    description: "pool material",
  },
  foil: {
    id: "create-pool-form.drop-down-option.material.foil",
    defaultMessage: "Foil",
    description: "pool material",
  },
  concrete: {
    id: "create-pool-form.drop-down-option.material.concrete",
    defaultMessage: "Concrete",
    description: "pool material",
  },
  fibreglass: {
    id: "create-pool-form.drop-down-option.material.fibreglass",
    defaultMessage: "Fibreglass",
    description: "pool material",
  },
  shape: {
    id: "create-pool-form.title.shape",
    defaultMessage: "Shape",
    description: "title: shape of a swimming pool.",
  },
  round: {
    id: "create-pool-form.drop-down-option.shape.round",
    defaultMessage: "Round",
    description: "pool shape",
  },
  rectangular: {
    id: "create-pool-form.drop-down-option.shape.rectangular",
    defaultMessage: "Rectangular",
    description: "pool shape",
  },
  kidney: {
    id: "create-pool-form.drop-down-option.shape.kidney",
    defaultMessage: "Kidney",
    description: "pool shape",
  },
  lShaped: {
    id: "create-pool-form.drop-down-option.shape.l-shaped",
    defaultMessage: "L-Shape",
    description: "pool shape",
  },
});

interface CreatePoolFormProps {
  goBack: () => void;
  showGoBack: boolean;
}

const CreatePoolForm = ({ goBack, showGoBack }: CreatePoolFormProps) => {
  const { formatMessage } = useIntl();
  const { state, setInState } = useForm();

  useEffect(() => {
    setInState("pool", "location", PoolLocation.Outdoor);
    setInState("pool", "disinfection", PoolDisinfectionType.Chlorine);
    setInState("pool", "shape", PoolShape.Round);
    setInState("pool", "material", PoolMaterial.Steel);
  }, [setInState]);

  const shapes = [
    { label: formatMessage(messages.round), value: PoolShape.Round },
    {
      label: formatMessage(messages.rectangular),
      value: PoolShape.Rectangular,
    },
    { label: formatMessage(messages.kidney), value: PoolShape.Kidney },
    {
      label: formatMessage(messages.lShaped),
      value: PoolShape.LShaped,
    },
  ];

  const materials = [
    {
      label: formatMessage(messages.steel),
      value: PoolMaterial.Steel,
    },
    {
      label: formatMessage(messages.tiles),
      value: PoolMaterial.Tiles,
    },
    { label: formatMessage(messages.foil), value: PoolMaterial.Foil },
    {
      label: formatMessage(messages.concrete),
      value: PoolMaterial.Concrete,
    },
    {
      label: formatMessage(messages.fibreglass),
      value: PoolMaterial.GlassFibre,
    },
  ];

  return (
    <div className={style.root}>
      {showGoBack && (
        <img src={srcGoBack} className={style.back} onClick={goBack} alt="" />
      )}
      <CategoryTitle title={formatMessage(messages.title)} />
      <TextInput
        label={formatMessage(messages.name)}
        update={(name: string) => setInState("pool", "name", name)}
        defaultValue={state.pool?.name}
      />
      <PoolVolume />
      <CategoryTitle title={formatMessage(messages.location)} />
      <div className={style.checks}>
        <div className={style.check}>
          <CheckBox
            label={formatMessage(messages.outdoor)}
            checked={state?.pool?.location === PoolLocation.Outdoor}
            onClick={() => setInState("pool", "location", PoolLocation.Outdoor)}
          />
        </div>
        <div className={style.check}>
          <CheckBox
            label={formatMessage(messages.indoor)}
            checked={state?.pool?.location === PoolLocation.Indoor}
            onClick={() => setInState("pool", "location", PoolLocation.Indoor)}
          />
        </div>
      </div>
      <div className={style.spacer} />
      <CategoryTitle title={formatMessage(messages.disinfection)} />
      <div className={style.checks}>
        <div className={style.check}>
          <CheckBox
            label={formatMessage(messages.chlorine)}
            checked={
              state?.pool?.disinfection === PoolDisinfectionType.Chlorine
            }
            onClick={() =>
              setInState("pool", "disinfection", PoolDisinfectionType.Chlorine)
            }
          />
        </div>
        <div className={style.check}>
          <CheckBox
            label={formatMessage(messages.bromine)}
            checked={state?.pool?.disinfection === PoolDisinfectionType.Bromine}
            onClick={() =>
              setInState("pool", "disinfection", PoolDisinfectionType.Bromine)
            }
          />
        </div>
        <div className={style.check}>
          <CheckBox
            label={formatMessage(messages.salt)}
            checked={state?.pool?.disinfection === PoolDisinfectionType.Salt}
            onClick={() =>
              setInState("pool", "disinfection", PoolDisinfectionType.Salt)
            }
          />
        </div>
      </div>
      <div className={style.ddSeg}>
        <CategoryTitle title={formatMessage(messages.shape)} />
        <DropDown
          label={formatMessage(messages.shape)}
          list={shapes}
          defaultValue={
            shapes.find((shape) => shape.value === state?.pool?.shape)?.label
          }
          update={(shape: string) => setInState("pool", "shape", shape)}
        />
      </div>
      <div className={style.ddSeg}>
        <CategoryTitle title={formatMessage(messages.material)} />
        <DropDown
          label={formatMessage(messages.material)}
          list={materials}
          defaultValue={
            materials.find((mat) => mat.value === state?.pool?.material)?.label
          }
          update={(material: string) =>
            setInState("pool", "material", material)
          }
        />
      </div>
    </div>
  );
};

export default CreatePoolForm;
