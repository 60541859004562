import React from "react";

import style from "./CategoryTitle.module.css";
import InfoIcon from "./Info";

interface CategoryTitleProps {
  title: string;
  message?: string;
}

const CategoryTitle = ({ title, message }: CategoryTitleProps) => (
  <div className={style.root}>
    <div className={style.title}>{title}</div>
    {message && <InfoIcon message={message} />}
  </div>
);

export default CategoryTitle;
